import React from 'react';
import { Query } from 'react-apollo';
import { GET_PARTNER_API_CLIENTS } from './queries';
import LoadingIndicator from '../common/LoadingIndicator';
import CardComponent from '../common/CardComponent';
import Websites from './Websites';
import InfoBoxGrey from './InfoBoxGrey';
import InfoBoxBlue from './InfoBoxBlue';
import CreationRow from './CreationRow';
import './index.sass';

export default function PartnerWebsites() {
  return (
    <div className="PartnerWebsites">
      <Query query={GET_PARTNER_API_CLIENTS}>
        {({ loading, error, data, refetch }) => {
          if (loading) return <LoadingIndicator />;
          if (error) return (<p>{`Error! ${error.message}`}</p>);
          data = (data && data.partnerApiClients) || [];

          if (data.length === 0) {
            return (
              <CardComponent titleH4 title="Online-Rechner">
                <div className="info-box-wrapper">
                  <InfoBoxBlue />
                </div>
                <CreationRow />
              </CardComponent>
            );
          }

          return (
            <React.Fragment>
              <CardComponent separator titleH4 title="Online-Rechner">
                <Websites
                  websites={data}
                  refetchWebsites={refetch}
                />
                <CreationRow />
              </CardComponent>
              <InfoBoxGrey />
            </React.Fragment>
          );
        }}
      </Query>
    </div>
  );
}
