import React from 'react';
import { HTMLTable } from '@blueprintjs/core';
import CardComponent from '../common/CardComponent';
import Row from './Row';
import './index.sass';

function PartnerData({ partnerData }) {
  return (
    <div className="PartnerData">
      <CardComponent title="Meine Daten">
        <HTMLTable>
          <tbody>
            <tr className="MyDataRow">
              <td>Partnernummer</td>
              <td colSpan="2">{partnerData.number}</td>
            </tr>
            <Row attr="firstname" title="Vorname" value={partnerData.firstname} />
            <Row attr="lastname" title="Nachname" value={partnerData.lastname} />
            <Row attr="company" title="Unternehmen" value={partnerData.company} />
            <Row attr="contactEmail" title="Kontakt E-Mail" value={partnerData.contactEmail} />
            <Row attr="phone" title="Telefonnummer" value={partnerData.phone} />
            <Row attr="website" title="Hauptwebsite" value={partnerData.website} />
            <Row attr="street" title="Straße" value={partnerData.street} />
            <Row attr="streetNumber" title="Nummer" value={partnerData.streetNumber} />
            <Row attr="addressAddition" title="Adresszusatz" value={partnerData.addressAddition} />
            <Row attr="zip" title="PLZ" value={partnerData.zip} />
            <Row attr="city" title="Stadt" value={partnerData.city} />
            <Row attr="country" title="Land" value={partnerData.country} />
            <Row attr="iban" title="IBAN" value={partnerData.iban} />
            <Row attr="bic" title="BIC" value={partnerData.bic} />
          </tbody>
        </HTMLTable>
      </CardComponent>
    </div>
  );
}

export default PartnerData;
