import React from 'react';
import CalculatedOffersCard from './calculatedOffersCard';
import PartnerLinkStatisticsCard from './partnerLinkStatisticsCard';
import NewContractsStatisticsCard from './newContractsStatisticsCard';
import './index.sass';

export default function Statistics() {
  return (
    <div className="Statistics">
      <CalculatedOffersCard />
      <PartnerLinkStatisticsCard />
      <NewContractsStatisticsCard />
    </div>
  );
}
