import React from 'react';
import { HTMLTable } from '@blueprintjs/core';
import ComponentAttribute from './ComponentAttribute';
import './DocumentsTable.sass';

function DocumentsTable({ component }) {
  return (
    <div className="DocumentsTable">
      <HTMLTable>
        <thead>
          <tr>
            <th>{component.title}</th>
            <th>
              {component.attributes.map((a, i) => <ComponentAttribute
                key={i}
                attribute={a}
                componentId={component.id}
              />)}
            </th>
          </tr>
        </thead>
        <tbody>
          { component.documents.map(doc => {
            return (
              <tr key={doc.id}>
                <td colSpan={2}>
                  <a href={doc.url} target="_blank" rel="noopener noreferrer">
                    {doc.title}
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </HTMLTable>
    </div>
  );
}

export default DocumentsTable;
