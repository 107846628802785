import React from 'react';
import { Query } from 'react-apollo';
import { HTMLTable } from '@blueprintjs/core';
import { GET_CHANGE_REQUESTS } from './queries';
import LoadingIndicator from '../../common/LoadingIndicator';
import Row from './Row';
import CreateButton from './CreateButton';
import './index.sass';

function ChangeRequests({ contractId }) {
  return (
    <Query query={GET_CHANGE_REQUESTS} variables={{ id: contractId }}>
      {({ loading, error, data }) => {
        if (loading) return <LoadingIndicator />;
        if (error) return (<p>{`Error! ${error.message}`}</p>);
        data = (data && data.contractChangeRequests) || [];

        let cardContent = <HTMLTable>
          <thead>
            <tr>
              <th>Angelegt am</th>
              <th>Erstellt von</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map(request => <Row key={request.id} request={request} />)}
          </tbody>
        </HTMLTable>;

        if (data.length === 0) cardContent = <p>Keine offenen Änderungsanträge</p>;

        return (
          <div className="ChangeRequests">
            <div className="create-button-wrapper">
              <CreateButton contractId={contractId} />
            </div>
            {cardContent}
          </div>
        );
      }}
    </Query>
  );
}

export default ChangeRequests;
