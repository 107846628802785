import React from 'react';
import { HTMLTable } from '@blueprintjs/core';

function Attachments({ attachments }) {
  return (
    <div className="Attachments row">
      <HTMLTable>
        <thead>
          <tr><th>Zusätzliche Dokumente</th></tr>
        </thead>
        <tbody>
          { attachments.map(attachment => {
            return (
              <tr key={attachment.id}>
                <td colSpan={2}>
                  <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                    {attachment.title}
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </HTMLTable>
    </div>
  );
}

export default Attachments;
