import React from 'react';
import { Query } from 'react-apollo';
import InfoBox from '../InfoBox';
import { GET_FOUR_CHARTS_STATISTICS, getQueryVariablesForEvent } from '../queries';
import CardComponent from '../../common/CardComponent';
import NoStatisticsMessage from '../NoStatisticsMessage';
import LoadingIndicator from '../../common/LoadingIndicator';
import LineChart from '../LineChart';
import { PARTNER_LINK_VISITED } from '../statisticEventsNames';
import { DAYS, MONTHS } from '../utils';

function PartnerLinkStatisticsCard() {
  return (
    <CardComponent titleH4 separator title='Klicks auf "Mein Partner-Link"'>
      <InfoBox>
        <p>
          Die Statistik zeigt die Anzahl aller Besucher die mit Hilfe Ihres Partner-Links
          auf den nammert.com Online-Rechner gelangt sind.
        </p>
      </InfoBox>
      <Query query={GET_FOUR_CHARTS_STATISTICS} variables={getQueryVariablesForEvent(PARTNER_LINK_VISITED)}>
        {({ loading, error, data }) => {
          if (loading) return <LoadingIndicator />;
          if (error) return (<p>{`Error! ${error.message}`}</p>);

          const valuesOf = (aggregations) => {
            return aggregations.map(a => { return a.value });
          }

          const currentWeekValues = valuesOf(data.currentWeek.eventAggregations);
          const lastWeekValues = valuesOf(data.lastWeek.eventAggregations);
          const currentMonthValues = valuesOf(data.currentMonth.eventAggregations);
          const lastMonthValues = valuesOf(data.lastMonth.eventAggregations);
          const currentYearValues = valuesOf(data.currentYear.eventAggregations);
          const lastYearValues = valuesOf(data.lastYear.eventAggregations);

          const allValues = [].concat(
            currentWeekValues,
            lastWeekValues,
            currentMonthValues,
            lastMonthValues,
            currentYearValues,
            lastYearValues,
          );

          if (allValues.reduce((a, b) => a + b, 0) === 0) {
            return <NoStatisticsMessage />;
          }

          return (
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <h4>Letzte Woche</h4>
                <LineChart data={lastWeekValues} labels={DAYS} />
              </div>
              <div className="col-xs-12 col-sm-6">
                <h4>Diese Woche</h4>
                <LineChart data={currentWeekValues} labels={DAYS} />
              </div>
              <div className="col-xs-12 col-sm-6">
                <h4>Letzter Monat</h4>
                <LineChart data={lastMonthValues} />
              </div>
              <div className="col-xs-12 col-sm-6">
                <h4>Dieser Monat</h4>
                <LineChart data={currentMonthValues} />
              </div>
              <div className="col-xs-12 col-sm-6">
                <h4>Letztes Jahr</h4>
                <LineChart data={lastYearValues} labels={MONTHS} />
              </div>
              <div className="col-xs-12 col-sm-6">
                <h4>Dieses Jahr</h4>
                <LineChart data={currentYearValues} labels={MONTHS} />
              </div>
            </div>
          );
        }}
      </Query>
    </CardComponent>
  );
}

export default PartnerLinkStatisticsCard;
