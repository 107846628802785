import React from 'react';
import { Query } from 'react-apollo';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import ProposalDetails from './ProposalDetails';
import { GET_PROPOSAL } from './queries';

function OfferedProposal({ match, history }) {
  return (
    <Query query={GET_PROPOSAL} variables={{ id: parseInt(match.params.id, 10) }}>
      {({ loading, error, data }) => {
        if (error) return <p>{`Error! ${error.message}`}</p>;
        if (loading) return <LoadingIndicator />;

        return (
          <ProposalDetails
            history={history}
            proposal={data.partnerProposal}
          />
        );
      }}
    </Query>
  );
}

export default OfferedProposal;
