import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LoadingIndicator from '../components/common/LoadingIndicator';
import RootContainer from './Root';
import ContractsContainer from './ContractsContainer';
import ProfileContainer from './ProfileContainer';
import PartnerDataContainer from './PartnerDataContainer';
import PartnerWebsites from '../components/partnerWebsites';
import { Contract } from './Contract';
import Layout from '../layout';
import Statistics from '../components/statistics';
import Provisions from './Provisions';
import OfferedProposals from './OfferedProposals';
import OfferedProposal from './OfferedProposal';
import NewOfferCalculator from './OfferCalculator/NewOffer';
import EditOfferCalculator from './OfferCalculator/EditOffer';
import NewDamageRequest from './DamageRequest';
import {
  newOfferCalculator,
  editOfferCalculator,
  offeredProposals,
  offeredProposal,
  newDamageRequest,
} from '../routes';
import { renewAccessToken } from '../utils/oauthClient';

export const GET_LOGGED_IN_PARTNER = gql`
  query {
    isLoggedIn
  }
`;

function MainRouter() {
  return (
    <Query query={GET_LOGGED_IN_PARTNER}>
      {({ loading, error, data }) => {
        if (loading) return <LoadingIndicator />;
        if (error || !data || !data.isLoggedIn) {
          renewAccessToken();
          return null;
        }

        return (
          <Router>
            <Layout>
              <Route exact path="/" component={ RootContainer } />
              <Route exact path="/vertraege" component={ ContractsContainer } />
              <Route path="/vertraege/:id" component={ Contract } />
              <Route path="/meine-daten" component={ PartnerDataContainer } />
              <Route path="/meine-online-rechner" component={ PartnerWebsites } />
              <Route path="/profil" component={ ProfileContainer } />
              <Route path="/statistiken" component={Statistics} />
              <Route path="/provisionen" component={Provisions} />
              <Route exact path={newOfferCalculator()} component={NewOfferCalculator} />
              <Route path={editOfferCalculator()} component={EditOfferCalculator} />
              <Route exact path={offeredProposals()} component={OfferedProposals} />
              <Route path={offeredProposal()} component={OfferedProposal} />
              <Route exact path={newDamageRequest()} component={NewDamageRequest} />
            </Layout>
          </Router>
        );
      }}
    </Query>
  );
}

export default MainRouter;
