import React from 'react';
import { Line } from 'react-chartjs-2';
import { stepSizeForDataset } from './utils';

export default function LineChart({ labels, data }) {
  const chartData = {
    labels: labels || data.map((a, index) => index + 1),
    datasets: [
      {
        data,
        fill: true,
        lineTension: 0.2,
        backgroundColor: 'rgba(0, 183, 177, 0.4)',
        borderColor: 'rgb(0, 183, 177)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgb(0, 183, 177)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [{
        ticks: {
          stepSize: stepSizeForDataset(data),
          suggestedMin: 0,
        },
      }],
    },
  };

  return (<Line options={options} data={chartData} legend={{ display: false }} />);
}
