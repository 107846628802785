import React from 'react';
import { Spinner } from '@blueprintjs/core';
import './LoadingIndicator.sass';

function LoadingIndicator() {
  return (
    <div className="LoadingIndicator">
      <Spinner size={Spinner.SIZE_STANDARD} />
    </div>
  );
}

export default LoadingIndicator;
