import React from 'react';
import { Mutation } from 'react-apollo';
import {
  Button,
  Alert,
  Intent,
} from '@blueprintjs/core';
import { DELETE_OFFER } from './queries';

class DeleteOffer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { alertIsOpen: false };
  }

  openAlert() {
    this.setState({ alertIsOpen: true });
  }

  closeAlert() {
    this.setState({ alertIsOpen: false });
  }

  handleResponse({ data: { deleteOffer } }) {
    if (deleteOffer.success) {
      this.props.refetchProposals();
    }
  }

  render() {
    return (
      <Mutation mutation={DELETE_OFFER}>
        {(deleteOffer, { loading }) => (
          <React.Fragment>
            <Button
              loading={loading}
              icon="trash"
              onClick={this.openAlert.bind(this)}
            />
            <Alert
              icon="trash"
              intent={Intent.DANGER}
              isOpen={this.state.alertIsOpen}
              cancelButtonText="Abbrechen"
              onCancel={this.closeAlert.bind(this)}
              confirmButtonText="Angebot löschen"
              onConfirm={() => {
                deleteOffer({
                  variables: { id: this.props.id },
                }).then(this.handleResponse.bind(this));
              }}
            >
              <p>Wollen Sie dieses Angebot wirklich löschen?</p>
            </Alert>
          </React.Fragment>
        )}
      </Mutation>
    );
  }
}

export default DeleteOffer;
