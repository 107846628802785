import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import {
  Button,
  Intent,
  FormGroup,
  InputGroup,
} from '@blueprintjs/core';

export const buildPartnerDataMutation = (attr) => {
  return gql`
    mutation UpdatePartner($attr: String!, $value: String!) {
      updatePartner(attribute: $attr, value: $value) {
        currentPartner {
          ${attr}
          errors {
            attribute
            messages
          }
        }
      }
    }
  `;
}

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
      errors: [],
    };

    this.updateValue = this.updateValue.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
  }

  updateValue(e) {
    this.setState({ value: e.target.value });
  }

  handleResponse(cache, mutationResult) {
    this.setState({ errors: [] });
    const partner = mutationResult.data.updatePartner.currentPartner;
    const errors = this._getAttributeErrors(partner);

    if (errors.length > 0) {
      this.setState({ errors });
    } else {
      const { attr } = this.props;
      cache.writeData({
        data: {
          currentPartner: {
            [attr]: partner[attr],
            __typename: 'Partner',
          },
        },
      });
      this.props.closeCallback();
    }
  }

  _getAttributeErrors(partner) {
    let errors = [];
    if (partner.errors.length > 0) {
      partner.errors.forEach(error => {
        if (error.attribute === this.props.attr) {
          errors = error.messages;
        }
      });
    }
    return errors;
  }

  render() {
    const { title, attr, closeCallback } = this.props;
    const { value, errors } = this.state;
    const intent = errors.length > 0 ? Intent.DANGER : Intent.NONE;

    return (
      <Mutation mutation={buildPartnerDataMutation(attr)}>
        {(updatePartner, { loading }) => (
          <React.Fragment>
            <td>
              <FormGroup helperText={errors.join(', ')} intent={intent}>
                <InputGroup
                  autoFocus
                  disabled={loading}
                  placeholder={title}
                  intent={intent}
                  value={value}
                  onChange={this.updateValue}
                />
              </FormGroup>
            </td>
            <td className="actions">
              <Button
                loading={loading}
                icon="small-tick"
                text="Speichern"
                intent={Intent.SUCCESS}
                onClick={() => {
                  updatePartner({
                    variables: { attr, value },
                    update: this.handleResponse,
                  });
                }}
              />
              <Button icon="small-cross" onClick={closeCallback} />
            </td>
          </React.Fragment>
        )}
      </Mutation>
    );
  }
}

export default Input;
