import React from 'react';
import { HTMLTable } from '@blueprintjs/core';
import DataRow from '../common/DataRow';
import { yesOrNo } from '../../utils/common';

function TrailerData({ data }) {
  let content = <React.Fragment>
    <DataRow title="Kennzeichen" value={data.trailerKennzeichen} />
    <DataRow title="Kennzeichen Typ" value={data.trailerKennzeichenTyp} />
    <DataRow title="Hersteller" value={data.trailerHersteller} />
    <DataRow title="Zulässiges Gesamtgewicht" value={data.trailerZulGewicht} addon="kg" />
    <DataRow title="Fahrgestellnummer" value={data.trailerFahrgestelltNr} />
    <DataRow title="Erstzulassung" value={data.trailerErstzulassung} />
  </React.Fragment>;

  if (data.trailerOnlyPort) {
    content = <DataRow title="Baujahr" value={data.trailerBaujahr} />;
  }

  return (
    <div className="TrailerData row">
      <HTMLTable>
        <thead>
          <tr><th>Trailer</th></tr>
        </thead>
        <tbody>
          <DataRow title="Hafentrailer" value={yesOrNo(data.trailerOnlyPort)} />
          {content}
        </tbody>
      </HTMLTable>
    </div>
  );
}

export default TrailerData;
