import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import {
  Button,
  Classes,
} from '@blueprintjs/core';

function ContractRow({ indexView, contract }) {
  return (
    <Table.Row>
      <Table.Cell>{contract.id}</Table.Cell>
      <Table.Cell>{contract.firstTimePublishedAt}</Table.Cell>
      <Table.Cell>{contract.stateTitle}</Table.Cell>
      <Table.Cell>{contract.data.lastname}</Table.Cell>
      <Table.Cell>{contract.data.firstname}</Table.Cell>
      <Table.Cell>{contract.data.address}</Table.Cell>
      <Table.Cell>{contract.data.boatName}</Table.Cell>
      <Table.Cell>{contract.formattedSumAfterTax}</Table.Cell>
      { indexView === true &&
        <Table.Cell>
          <Link to={`/vertraege/${contract.id}`}>
            <Button className={Classes.MINIMAL} icon="eye-open" />
          </Link>
        </Table.Cell>
      }
    </Table.Row>
  );
}

export default ContractRow;
