import React from 'react';

export default function InfoBoxBlue() {
  return (
    <div className="bp3-callout bp3-icon-calculator bp3-intent-primary">
      <h5 className="bp3-heading">Jetzt Online-Rechner auf Ihrer Website einbinden</h5>
      Binden Sie den Nammert Online Rechner auf Ihrer Website ein, um alle Vorteile Ihres Partner
      Programms zu nutzen.
      <br/>
      <br/>
      Gelangt ein zukünftiger Vertragsnehmer auf Ihre Website und erstellt einen
      Versicherungsvorschlag über den auf Ihrer Website eingebundenen Online Rechner, dann erhalten
      Sie automatisch Ihre Provision. Ihre Partnernummer ist dann automatisch bei dem Antrag
      hinterlegt und kann auch nicht mehr gelöscht werden.
    </div>
  );
}
