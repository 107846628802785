import React from 'react';
import Profile from '../components/Profile';
import { changePassword } from '../utils/user';
import './ProfileContainer.sass';

class ProfileContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: { value: '', errors: [] },
      password: { value: '', errors: [] },
      passwordConfirmation: { value: '', errors: [] },
      success: false,
      errors: [],
    };
  }

  render() {
    return (
      <div className="ProfileContainer">
        <Profile
          currentPassword={this.state.currentPassword}
          password={this.state.password}
          passwordConfirmation={this.state.passwordConfirmation}
          success={this.state.success}
          onChange={this.onChange.bind(this)}
          onSubmit={this.onSubmit.bind(this)}
        />
      </div>
    );
  }

  onChange(e) {
    const target = e.currentTarget;
    this.setState({ [target.name]: { value: target.value, errors: [] } });
  }

  resetErrors() {
    this.setState({
      currentPassword: { value: this.state.currentPassword.value, errors: [] },
      password: { value: this.state.password.value, errors: [] },
      passwordConfirmation: { value: this.state.passwordConfirmation.value, errors: [] },
    });
  }

  onSubmit(e) {
    e.preventDefault();
    return changePassword(this.normalizeCredentials()).then(({ success, errors }) => {
      this.resetErrors();

      const newState = {...this.state};
      errors.forEach(error => {
        newState[error.attribute].errors = error.messages;
      });

      this.setState({...newState, success });
    });
  }

  normalizeCredentials() {
    return {
      currentPassword: this.state.currentPassword.value,
      password: this.state.password.value,
      passwordConfirmation: this.state.passwordConfirmation.value,
    }
  }
}

export default ProfileContainer;
