import React from 'react';
import CardComponent from '../common/CardComponent';
import DocumentsTable from './DocumentsTable';

function ContractComponents({ components, externalReference }) {
  return (
    <div className="ContractComponents">
      <CardComponent separator title="Verträge" rightTitle={externalReference}>
        { components.map((component, index) => (
          <DocumentsTable key={index} component={component} />
        ))}
      </CardComponent>
    </div>
  );
}

export default ContractComponents;
