import React from 'react';
import { Link } from 'react-router-dom';
import {
  HTMLTable,
  Button,
  Intent,
} from '@blueprintjs/core';
import CardComponent from '../../components/common/CardComponent';
import BackButton from '../../components/contractDetails/BackButton';
import GeneralCard from './GeneralCard';
import BoatCard from './BoatCard';
import DocumentsTable from '../../components/contractDetails/DocumentsTable';
import { editOfferCalculatorForId } from '../../routes';
import './ProposalDetails.sass';

function ProposalDetails({ history, proposal }) {
  return (
    <div className="ProposalDetails">
      <BackButton history={history} />
      <Link to={editOfferCalculatorForId(proposal.id)}>
        <Button
          icon="document"
          intent={Intent.PRIMARY}
          text={`Vorschlag kostenpflichtig für ${proposal.formattedSumAfterTax} beantragen`}
        />
      </Link>
      <GeneralCard data={proposal.data} />
      <BoatCard data={proposal.data} />
      <CardComponent separator title="Dokumente" rightTitle={proposal.externalReference}>
        <HTMLTable className="proposal-documents">
          <tbody>
            {proposal.offerDocument &&
              <tr>
                <td>
                  <a href={proposal.offerDocument.url} target="_blank" rel="noopener noreferrer">
                    Vorschlag.pdf
                  </a>
                </td>
              </tr>
            }
            {proposal.additionalDocuments.map(doc => {
              return (
                <tr key={doc.id}>
                  <td colSpan={2}>
                    <a href={doc.url} target="_blank" rel="noopener noreferrer">
                      {doc.title}
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </HTMLTable>
        {proposal.components.filter(c => c.documents.length > 0).map(component => (
          <DocumentsTable
            key={component.id}
            component={component}
          />
        ))}
      </CardComponent>
    </div>
  );
}

export default ProposalDetails;
