import React from 'react';
import {
  Card,
  Elevation,
} from '@blueprintjs/core';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import LoadingIndicator from '../components/common/LoadingIndicator';
import ContractsTable from '../components/ContractsTable';
import ContractsPagination from '../components/ContractsPagination';

export const GET_CONTRACTS = gql`
  query PartnerContractsCollection($offset: Int, $limit: Int) {
    partnerContractsCollection(offset: $offset, limit: $limit) {
      count
      nodes {
        id
        firstTimePublishedAt
        stateTitle
        formattedSumAfterTax
        data {
          lastname
          firstname
          address
          boatName
        }
      }
    }
  }
`;

export function Contracts({ per, page, offset, toPrev, toNext }) {
  return (
    <Query query={GET_CONTRACTS} variables={{ offset, limit: per }}>
      {({ loading, error, data }) => {
        data = (data && data.partnerContractsCollection) || {};
        if (error) return (<p>{`Error! ${error.message}`}</p>);
        const count = data.count || 0;
        return (
          <React.Fragment>
            <Card elevation={Elevation.TWO} style={{ position: 'relative', padding: '5px' }}>
              { loading && <LoadingIndicator /> }
              <ContractsTable indexView contracts={data.nodes || []} />
            </Card>
            { count > per &&
              <ContractsPagination
                current={page}
                last={Math.ceil(count / per)}
                onPrev={toPrev}
                onNext={toNext}
              />
            }
          </React.Fragment>
        );
      }}
    </Query>
  );
}
