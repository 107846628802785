import React from 'react';
import {
  Button,
  Classes,
} from '@blueprintjs/core';
import './BackButton.sass';

function BackButton({ history }) {
  const onGoBack = () => { history.goBack() }
  return (
    <Button
      className={`BackButton ${Classes.MINIMAL}`}
      icon="undo"
      text="Zurück"
      onClick={onGoBack}
    />
  );
}

export default BackButton;
