import React from 'react';

function CalculatorIFrame({ url }) {
  return (
    <div style={{ width: '100%', height: 'calc(100vh - 100px)' }}>
      <iframe
        title="OfferCalculator"
        width="100%"
        height="100%"
        frameBorder="0"
        src={url}
      ></iframe>
    </div>
  );
}

export default CalculatorIFrame;
