import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

function ProvisionRow({ provision }) {
  const link = <Link to={`/vertraege/${provision.contractId}`}>{provision.contractId}</Link>;
  let transactionDescription = <p dangerouslySetInnerHTML={{ __html: provision.description }} />;

  if (['PercentageTransaction', 'UnderYearlyPercentageTransaction'].includes(provision.transactionTypeTitle)) {
    transactionDescription = <p>
      Provision {provision.percentageForPercentCalculation}
      <br/>
      {provision.componentTypeTitle} Vertrag ({link})
      <br/>
      {provision.formattedStartDate} - {provision.formattedEndDate}
      <span> | {provision.formattedPaymentInterval}</span>
      {provision.externalPartnerReference &&
        <React.Fragment>
          <br/>
          {provision.externalPartnerReference}
        </React.Fragment>
      }
    </p>;
  } else if (['AmountTransaction', 'UnderYearlyAmountTransaction'].includes(provision.transactionTypeTitle)) {
    transactionDescription = <p>
      Einmalige Provision
      <br/>
      {provision.componentTypeTitle} Vertrag ({link})
      {provision.externalPartnerReference &&
        <React.Fragment>
          <br/>
          {provision.externalPartnerReference}
        </React.Fragment>
      }
    </p>;
  } else if (provision.transactionTypeTitle === 'PayoutTransaction') {
    transactionDescription = <p>
      {provision.description}
      <br/>
      <a href={provision.payoutPdfPath} target="_blank" rel="noopener noreferrer">PDF</a>
      &nbsp;|&nbsp;
      <a href={provision.payoutExcelPath} target="_blank" rel="noopener noreferrer">Excel</a>
      {provision.externalPartnerReference &&
        <React.Fragment>
          <br/>
          {provision.externalPartnerReference}
        </React.Fragment>
      }
    </p>;
  }

  return (
    <Table.Row>
      <Table.Cell className={provision.processType === '-' ? 'red-sign' : 'green-sign'}>
        {provision.processType}
      </Table.Cell>
      <Table.Cell>{transactionDescription}</Table.Cell>
      <Table.Cell>{`${provision.processType} ${provision.formattedAmount}`}</Table.Cell>
    </Table.Row>
  );
}

export default ProvisionRow;
