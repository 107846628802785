import React from 'react';
import { Button } from '@blueprintjs/core';

class DeleteButton extends React.Component {
  onButtonClick(e) {
    this.props.onClick(this.props.fileIndex);
  }

  render() {
    return (<Button small icon="trash" onClick={this.onButtonClick.bind(this)} />);
  }
}

export default DeleteButton;
