import React from 'react';
import { destroyTokenAndRedirect } from '../utils/oauthClient';

class OauthDestroyAndRedirect extends React.Component {
  componentWillMount() {
    destroyTokenAndRedirect();
  }

  render() {
    return "";
  }
}

export default OauthDestroyAndRedirect;
