import gql from 'graphql-tag';

export const GET_OFFERED = gql`
  query PartnerOfferedCollection($offset: Int, $limit: Int) {
    partnerOfferedCollection(offset: $offset, limit: $limit) {
      count
      nodes {
        id
        clientEmail
        formattedCreatedAt
        data {
          fahrzeugtyp
          boatTotalValue
          sortedComponents {
            id
            title
          }
        }
      }
    }
  }
`;

export const DELETE_OFFER = gql`
  mutation DeleteOffer($id: ID!) {
    deleteOffer(id: $id) {
      success
      error
    }
  }
`;
