import gql from 'graphql-tag';

export const GET_PARTNER_API_CLIENTS = gql`
  query PartnerApiClients {
    partnerApiClients {
      id
      accessId
      origin
      defaultBoatType
    }
  }
`;

export const GET_BOAT_TYPES = gql`
  query {
    boatTypes {
      value
      title
    }
  }
`;

export const CREATE_PARTNER_API_CLIENT = gql`
  mutation CreatePartnerApiClient($origin: String!) {
    createPartnerApiClient(origin: $origin) {
      partnerApiClient {
        id
        accessId
        origin
        defaultBoatType
        errors {
          attribute
          messages
        }
      }
    }
  }
`;

export const UPDATE_PARTNER_API_CLIENT = gql`
  mutation UpdatePartnerApiClient($id: ID!, $attributes: UpdatePartnerApiClientInput!) {
    updatePartnerApiClient(id: $id, attributes: $attributes) {
      partnerApiClient {
        id
        accessId
        origin
        defaultBoatType
        errors {
          attribute
          messages
        }
      }
    }
  }
`;

export const DELETE_PARTNER_API_CLIENT = gql`
  mutation DeletePartnerApiClient($id: ID!) {
    deletePartnerApiClient(id: $id) {
      success
      error
    }
  }
`;
