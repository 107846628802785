import React from 'react';
import { Link } from 'react-router-dom';
import { Responsive } from 'semantic-ui-react';
import {
  Alignment,
  Navbar,
  NavbarGroup
} from '@blueprintjs/core';
import { OnlineCalculatorLink } from '../containers/OnlineCalculatorLink';
import ProfileButton from '../containers/ProfileButton';
import Sidebar from './Sidebar';
import ToggleButton from './ToggleButton';
import './Layout.sass';

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };

    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  toggleSidebar() {
    this.setState({ open: !this.state.open });
  }

  render() {
    return (
      <React.Fragment>
        <Responsive minWidth={1001}>
          <Sidebar open={true} toggleable={false} />
        </Responsive>
        <Responsive maxWidth={1000}>
          <Sidebar open={this.state.open} toggleable={true} toggleSidebar={this.toggleSidebar} />
        </Responsive>
        <Navbar fixedToTop>
          <Responsive maxWidth={1000}>
            <ToggleButton onToggleButtonClick={this.toggleSidebar} />
          </Responsive>
          <NavbarGroup align={Alignment.RIGHT}>
            <Link to="/profil">
              <ProfileButton />
            </Link>
            <OnlineCalculatorLink />
          </NavbarGroup>
        </Navbar>
        <div id="app-wrapper">
          <div id="app-content">
            {this.props.children}
          </div>
        </div>
      </React.Fragment>
    );
  }
};

export default Layout;
