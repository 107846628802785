import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Intent,
} from '@blueprintjs/core';

function CreateButton({ linkTo }) {
  return (
    <Link to={linkTo}>
      <Button
        intent={Intent.PRIMARY}
        icon="flame"
        text="Schaden melden"
      />
    </Link>
  );
}

export default CreateButton;
