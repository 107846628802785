import React from 'react';
import { HTMLTable } from '@blueprintjs/core';
import './AttachmentsTable.sass';

function AttachmentsTable({ attachments }) {
  return (
    <HTMLTable className="AttachmentsTable">
      <thead>
        <tr><th>Dokumente</th></tr>
      </thead>
      <tbody>
        { attachments.map(attachment => {
          return (
            <tr key={attachment.id}>
              <td>
                <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                  {attachment.title}
                </a>
              </td>
            </tr>
          );
        })}
      </tbody>
    </HTMLTable>
  );
}

export default AttachmentsTable;
