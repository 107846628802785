import React from 'react';
import { HTMLTable } from '@blueprintjs/core';
import CardComponent from '../../components/common/CardComponent';
import DataRow from '../../components/common/DataRow';

function BoatCard({ data }) {
  return (
    <CardComponent title="Boot">
      <div className="row">
        <HTMLTable>
          <tbody>
            <DataRow title="Gesamtwert" value={data.overallPrice} />
            <DataRow title="Wert Boot" value={data.wertBoot} />
            <DataRow title="Wert Trailer" value={data.wertTrailer} />
            <DataRow title="Wert technische Ausrüstung" value={data.wertTechnischeAusruestung} />
            <DataRow title="Wert Beiboot" value={data.wertBeiboot} />
            <DataRow title="Wert Zubehör" value={data.wertZubehoer} />
            <DataRow title="Wert persönliche Effekten" value={data.wertPersoenlicheEffekten} />
            <DataRow title="Wert Wassersportausrüstung" value={data.wertWassersportAusruestung} />
          </tbody>
        </HTMLTable>
      </div>
    </CardComponent>
  );
}

export default BoatCard;
