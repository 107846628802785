import React from 'react';
import {
  Button,
  Dialog,
  Intent,
} from '@blueprintjs/core';
import { Responsive } from 'semantic-ui-react';
import CreateMutation from './CreateMutation';

class CreateButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dialogOpen: false };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen() {
    this.setState({ dialogOpen: true });
  }

  handleClose() {
    this.setState({ dialogOpen: false });
  }

  render() {
    return (
      <React.Fragment>
        <Responsive minWidth={551}>
          <Button
            onClick={this.handleOpen}
            intent={Intent.PRIMARY}
            icon="plus"
            text="Änderungsantrag anlegen"
          />
        </Responsive>
        <Responsive maxWidth={550}>
          <Button
            onClick={this.handleOpen}
            intent={Intent.PRIMARY}
            icon="plus"
          />
        </Responsive>
        <Dialog
          isOpen={this.state.dialogOpen}
          onClose={this.handleClose}
          title="Änderungsantrag anlegen"
          style={{ width: '600px' }}
        >
          <CreateMutation
            contractId={this.props.contractId}
            closeCallback={this.handleClose}
          />
        </Dialog>
      </React.Fragment>
    );
  }
}

export default CreateButton;
