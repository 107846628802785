import React from 'react';
import { Table } from 'semantic-ui-react';
import WebsiteRow from './WebsiteRow';
import './Websites.sass';

function Websites({ websites, refetchWebsites }) {
  return (
    <Table className="Websites">
      <Table.Body>
        {websites.map(apiClient =>
          <WebsiteRow
            key={apiClient.id}
            apiClient={apiClient}
            refetchWebsites={refetchWebsites}
          />
        )}
      </Table.Body>
    </Table>
  );
}

export default Websites;
