import React from 'react';
import { Bar } from 'react-chartjs-2';
import { stepSizeForDataset } from './utils';

export default function BarsChart({ labels, data }) {
  const chartData = {
    labels: labels || data.set1.map((a, index) => index + 1),
    datasets: [
      {
        stack: 'stack1',
        label: 'nammert.com Online-Rechner',
        data: data.set1,
        backgroundColor: 'rgb(0, 183, 177)',
      },
      {
        stack: 'stack1',
        label: 'Ihr(e) Online-Rechner',
        data: data.set2,
        backgroundColor: 'rgba(0, 202, 195, 1.0)',
      },
    ],
  };

  const sumOfSets = data.set1.map((value, index) => {
    return value + data.set2[index];
  });

  const options = {
    scales: {
      yAxes: [{
        ticks: {
          stepSize: stepSizeForDataset(sumOfSets),
          suggestedMin: 0,
        },
      }],
    },
  };

  return (<Bar options={options} data={chartData} legend={{ display: false }} />);
}
