import React from 'react';
import { Table } from 'semantic-ui-react';
import ProvisionRow from './ProvisionRow';
import './ProvisionsTable.sass';

function ProvisionsTable({ provisions, sum }) {
  return (
    <Table size='small' className="ProvisionsTable">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="3">Transaktionsverlauf</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {provisions.map(p => <ProvisionRow key={p.id} provision={p} />)}
      </Table.Body>
    </Table>
  );
}

export default ProvisionsTable;
