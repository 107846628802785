import gql from 'graphql-tag';

export const GET_NEW_OFFER_URL = gql`
  query CurrentPartner {
    currentPartner {
      offerCalculatorApiUrl
    }
  }
`;

export const GET_EDIT_OFFER_URL = gql`
  query CurrentPartner($offerId: Int!) {
    currentPartner {
      editOfferApiUrl(offerId: $offerId)
    }
  }
`;
