import React from 'react';
import { Mutation } from 'react-apollo';
import {
  Button,
  Intent,
  FormGroup,
  InputGroup,
} from '@blueprintjs/core';
import {
  GET_PARTNER_API_CLIENTS,
  CREATE_PARTNER_API_CLIENT,
} from './queries';

class CreateWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      origin: '',
      errors: '',
    };

    this.updateOrigin = this.updateOrigin.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
  }

  updateOrigin(e) {
    this.setState({ origin: e.target.value });
  }

  handleResponse(cache, mutationResult) {
    this.setState({ errors: '' });
    const apiClient = mutationResult.data.createPartnerApiClient.partnerApiClient;

    if (apiClient.errors.length > 0) {
      const newState = {...this.state};
      apiClient.errors.forEach(error => {
        if (error.attribute === 'origin') {
          newState.errors = error.messages.join(', ');
        } else {
          this.handleGlobalErrors(`
            apiClient ${apiClient.id} attribute ${error.attribute} has errors ${error.messages.join(', ')}
          `);
        }
      });
      this.setState({...newState});
    }
    else {
      const { partnerApiClients } = cache.readQuery({ query: GET_PARTNER_API_CLIENTS });
      cache.writeQuery({
        query: GET_PARTNER_API_CLIENTS,
        data: { partnerApiClients: partnerApiClients.concat([apiClient]) }
      });
      this.props.cancelCallback();
    }
  }

  handleGlobalErrors(errors) {
    global.Sentry.captureMessage(`
      Error by partnerApiClient creation,
      errors: ${errors}.
    `);
    this.setState({
      errors: 'Beim hinzufügen der Webseite ist ein Fehler aufgetreten. Ein Administrator wurde bereits informiert. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.',
    });
  }

  render() {
    const { origin, errors } = this.state;
    const intent = errors.length > 0 ? Intent.DANGER : Intent.NONE;
    const info = 'Tragen Sie hier nur die URL ohne weitere Unterseite ein. z.B.: https://nammert.com';
    return (
      <Mutation mutation={CREATE_PARTNER_API_CLIENT}>
        {(createPartnerApiClient, { loading }) => (
          <tr>
            <td colSpan="2">
              <FormGroup helperText={errors || info} intent={intent}>
                <InputGroup
                  autoFocus
                  placeholder="Auf welcher Website soll der Online-Rechner eingebunden werden?"
                  type="text"
                  value={origin}
                  onChange={this.updateOrigin}
                  intent={intent}
                />
              </FormGroup>
            </td>
            <td className="actions">
              <Button
                loading={loading}
                icon="small-tick"
                text="Speichern"
                intent={Intent.SUCCESS}
                onClick={() => {
                  createPartnerApiClient({
                    variables: { origin },
                    update: this.handleResponse,
                  });
                }}
              />
              <Button icon="small-cross" onClick={this.props.cancelCallback} />
            </td>
          </tr>
        )}
      </Mutation>
    );
  }
}

export default CreateWebsite;
