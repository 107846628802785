import React from 'react';
import { Tag, Intent } from '@blueprintjs/core';
import { isPresent } from '../../utils/common';

const warningIdentifiers = ['expiration'];

function intentForId(id) {
  if (warningIdentifiers.includes(id)) return Intent.WARNING;

  return Intent.NONE;
}

function ComponentAttribute({ attribute, componentId }) {
  if (!isPresent(attribute.key)) {
    global.Sentry.captureMessage(`No key for ${attribute.identifier} of component ${componentId}`);
    return null;
  }
  if (!isPresent(attribute.value)) {
    global.Sentry.captureMessage(`No value for ${attribute.identifier} of component ${componentId}`);
    return null;
  }
  return <Tag intent={intentForId(attribute.identifier)}>
    {`${attribute.key}: ${attribute.value}`}
  </Tag>;
}

export default ComponentAttribute;
