import gql from 'graphql-tag';

export const GET_PROPOSAL = gql`
  query PartnerProposal($id: ID!) {
    partnerProposal(id: $id) {
      id
      formattedSumAfterTax
      externalPartnerReference
      offerDocument {
        id
        title
        url
      }
      additionalDocuments {
        id
        title
        url
      }
      data {
        fahrzeugtyp
        fahrgebiet
        baujahr
        paymentPeriod
        overallPrice
        wertBoot
        wertTrailer
        wertTechnischeAusruestung
        wertBeiboot
        wertElektronikgeraete
        wertZubehoer
        wertPersoenlicheEffekten
        wertWassersportAusruestung
      }
      components {
        id
        title
        attributes {
          identifier
          key
          value
        }
        documents {
          id
          title
          url
        }
      }
    }
  }
`;
