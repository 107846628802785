import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import LoadingIndicator from '../components/common/LoadingIndicator';
import ContractDetails from '../components/ContractDetails';

export const GET_CONTRACT = gql`
  query PartnerContract($id: ID!) {
    partnerContract(id: $id) {
      id
      externalPartnerReference
      data {
        version
        sex
        lastname
        firstname
        countryTitle
        street
        number
        zip
        city
        addressAddition
        beruf
        birthday
        email
        telefon
        boatUsage
        bootName
        bootKennzeichen
        bootTeilnahmeRegatten
        bootEigenbau
        bootRumpfmaterial
        bootRumpfmaterialBeschreibung
        bootVerdraenger
        bootMastType
        bootBaunummer
        bootMotornummer
        bootHersteller
        bootModel
        bootFaster100KmPerHour
        bootSpeed
        bootLaenge
        bootBreite
        bootTiefgang
        beibootAvailable
        wertBeiboot
        beibootHersteller
        beibootKennzeichen
        beibootMotorleistung
        beibootMotornummer
        beibootBaunummer
        beibottModel
        beibootBaujahr
        beibootMotorhersteller
        beibootMotorbaujahr
        liegeplatzSommerCountry
        liegeplatzSommerCity
        liegeplatzSommerMarina
        liegeplatzWinterCountry
        liegeplatzWinterCity
        liegeplatzWinterMarina
        trailerRequired
        trailerOnlyPort
        trailerBaujahr
        trailerKennzeichen
        trailerKennzeichenTyp
        trailerHersteller
        trailerZulGewicht
        trailerFahrgestelltNr
        trailerErstzulassung
        vertragsbeginn
        sepa
        ddAccountOwner
        ddAccountAddress
        ddBankCode
        ddAccountNo
        images {
          id
          url
        }
        partner {
          externalPartnerReference
        }
      }
      components {
        id
        title
        attributes {
          identifier
          key
          value
        }
        documents {
          id
          title
          url
        }
      }
      attachments {
        id
        title
        url
      }
    }
  }
`;

export function Contract({ match, history }) {
  return (
    <Query query={GET_CONTRACT} variables={{ id: parseInt(match.params.id, 10) }}>
      {({ loading, error, data }) => {
        if (error) return <p>{`Error! ${error.message}`}</p>;
        if (loading) return <LoadingIndicator />;
        return <ContractDetails history={history} contract={data.partnerContract} />;
      }}
    </Query>
  );
}
