import React from 'react';
import {
  Button,
  ButtonGroup,
} from '@blueprintjs/core';
import './ProvisionsPagination.sass';

function ProvisionsPagination({ current, last, onPrev, onNext }) {
  return (
    <div className="ProvisionsPagination">
      <p>{`Seite ${current}/${last}`}</p>
      <ButtonGroup>
        { current > 1 &&
          <Button icon="fast-backward" onClick={onPrev}>Vorherige</Button>
        }
        { current < last &&
          <Button rightIcon="fast-forward" onClick={onNext}>Nächste</Button>
        }
      </ButtonGroup>
    </div>
  );
}

export default ProvisionsPagination;
