import React from 'react';
import { setTokenFormUri } from '../utils/oauthClient';

class OauthRedirect extends React.Component {
  componentWillMount() {
    setTokenFormUri(window.location);
  }

  render() {
    return "";
  }
}

export default OauthRedirect;
