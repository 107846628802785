import React from 'react';
import queryString from 'query-string';
import { ProvisionsQuery } from './ProvisionsQuery';
import './Provisions.sass';

function Provisions({ location, history }) {
  const PER = 25;
  const params = queryString.parse(location.search);
  const page = parseInt(params.page || '1', 10) || 1;

  const navigateTo = (p) => history.push(`/provisionen?page=${p}`);
  const toPrev = () => navigateTo(page - 1);
  const toNext = () => navigateTo(page + 1);
  return (
    <ProvisionsQuery
      per={PER}
      page={page}
      offset={page > 1 ? ((page - 1) * PER) : 0}
      toPrev={toPrev}
      toNext={toNext}
    />
  );
}

export default Provisions;
