import React from 'react';
import {
  Button,
  Intent,
  HTMLTable,
} from '@blueprintjs/core';
import CreateWebsite from './CreateWebsite';
import './CreationRow.sass';

class CreationRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { createMode: false };
  }

  showCreateMode() {
    this.setState({ createMode: true });
  }

  hideCreateMode() {
    this.setState({ createMode: false });
  }

  render() {
    let content = <CreateWebsite cancelCallback={this.hideCreateMode.bind(this)} />;
    if (!this.state.createMode) {
      content = <tr>
        <td colSpan="2">
          <Button
            onClick={this.showCreateMode.bind(this)}
            intent={Intent.PRIMARY}
            icon="plus"
            text="Online-Rechner hinzufügen"
          />
        </td>
      </tr>;
    }

    return (
      <HTMLTable className="CreationRow">
        <tbody>{content}</tbody>
      </HTMLTable>
    );
  }
}

export default CreationRow;
