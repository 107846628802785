import React from 'react';
import {
  Card,
  Elevation,
} from '@blueprintjs/core';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import ProvisionsTable from './ProvisionsTable';
import ProvisionsPagination from './ProvisionsPagination';
import ProvisionsOverview from './ProvisionsOverview';
import { Redirect } from 'react-router-dom';

export const GET_PROVISIONS = gql`
  query CurrentPartnerProvisions($offset: Int, $limit: Int) {
    currentPartner {
      parentPartnerAccountType
      wallet {
        sum
        formattedSum
        nextPayoutDate
        transactions(offset: $offset, limit: $limit) {
          count
          nodes {
            id
            processType
            formattedCreatedAt
            formattedAmount
            formattedStartDate
            formattedEndDate
            formattedPaymentInterval
            componentTypeTitle
            contractPath
            contractId
            externalPartnerReference
            percentageForPercentCalculation
            description
            transactionTypeTitle
            payoutPdfPath
            payoutExcelPath
          }
        }
      }
    }
  }
`;

export function ProvisionsQuery({ per, page, offset, toPrev, toNext }) {
  return (
    <Query query={GET_PROVISIONS} variables={{ offset, limit: per }}>
      {({ loading, error, data }) => {
        if (loading) return 'Loading...';
        if (error) return (<p>{`Error! ${error.message}`}</p>);
        const { wallet, parentPartnerAccountType } = data.currentPartner;

        if (parentPartnerAccountType === 'sub_account') {
          return <Redirect to="/" />
        }

        if ((wallet.transactions.nodes|| []).length === 0) {
          return <ProvisionsOverview />
        }

        return (
          <div className="Provisions">
            <div className="wallet-informations">
              <p className="saldo">Saldo: {wallet.formattedSum}</p>
              {wallet && wallet.nextPayoutDate &&
                <p className="next-payment">
                  Nächster Auszahlungstermin: {wallet.nextPayoutDate}
                </p>
              }
            </div>
            <Card elevation={Elevation.TWO} style={{ position: 'relative', padding: '5px' }}>
              {loading && <LoadingIndicator />}
              <ProvisionsTable provisions={wallet.transactions.nodes || []} />
            </Card>
            {wallet.transactions.count > per &&
              <ProvisionsPagination
                current={page}
                last={Math.ceil(wallet.transactions.count / per)}
                onPrev={toPrev}
                onNext={toNext}
              />
            }
          </div>
        );
      }}
    </Query>
  );
}
