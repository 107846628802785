import React from 'react';
import { Icon } from '@blueprintjs/core';
import './ToggleButton.sass';

function ToggleButton({ onToggleButtonClick }) {
  return (
    <div className="ToggleButton" onClick={onToggleButtonClick}>
      <Icon icon="menu" iconSize={30} />
    </div>
  );
}

export default ToggleButton;
