import ClientOAuth2 from 'client-oauth2';
import urlParse from 'url-parse';
import { Base64 } from 'js-base64';
import { rootRoute } from '../routes';

const AFTER_REDIRECT_PATH_NAME = Base64.encode('one_checkout_redirect_pathname');
const OAUTH_KEY = Base64.encode('one_checkout_oa_b64');
const PARTNER_TRACKING_DISABLED = Base64.encode('partner-tracking-disabled');

const auth = new ClientOAuth2({
  clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
  accessTokenUri: process.env.REACT_APP_OAUTH_ACCESSTOKEN_URI,
  authorizationUri: process.env.REACT_APP_OAUTH_AUTHORIZATION_URI,
  redirectUri: process.env.REACT_APP_OAUTH_REDIRECT_URI,
})

export function getAccessToken() {
  const tokenB64 = window.localStorage.getItem(OAUTH_KEY);

  if (!tokenB64) {
    return null;
  }

  const token = JSON.parse(Base64.decode(tokenB64));

  if (token && token.access_token) {
    return token.access_token;
  }

  return null;
}

export function isPartnerTrackingDisabled() {
  const disabledTracking = window.localStorage.getItem(PARTNER_TRACKING_DISABLED);

  return disabledTracking != null && JSON.parse(disabledTracking) === true;
}

export function deleteAccessToken() {
  window.localStorage.removeItem(PARTNER_TRACKING_DISABLED);
  window.localStorage.removeItem(OAUTH_KEY);
}

export function renewAccessToken() {
  window.localStorage.setItem(OAUTH_KEY, Base64.encode(JSON.stringify({})));
  redirectToOauthServerUri();
}

export function destroyTokenAndRedirect() {
  deleteAccessToken();
  window.localStorage.setItem(PARTNER_TRACKING_DISABLED, "true");
  window.location = rootRoute();
}

export function setTokenFormUri(uri) {
  auth.token.getToken(uri).then(data => {
    window.localStorage.setItem(OAUTH_KEY, Base64.encode(JSON.stringify(data.data)));

    const redirectPathBase64 = window.localStorage.getItem(AFTER_REDIRECT_PATH_NAME)
    let redirectPath = rootRoute();

    if (redirectPathBase64) {
      redirectPath = Base64.decode(redirectPathBase64);
    }

    window.location = redirectPath;
  });
}

function redirectToOauthServerUri() {
  saveLocalPath();
  window.location = auth.token.getUri();
}

function saveLocalPath() {
  if (window.location.pathname !== oAuthPathname()) {
    window.localStorage.setItem(AFTER_REDIRECT_PATH_NAME, Base64.encode(window.location.pathname));
  }
}

function oAuthPathname() {
  return urlParse(process.env.REACT_APP_OAUTH_REDIRECT_URI).pathname
}
