import React from 'react';
import {
  Button,
  Dialog,
  Classes,
} from '@blueprintjs/core';
import AttachmentsTable from './AttachmentsTable';
import './Row.sass';

export const RED = '#d2322d';
export const ORANGE = '#ec971f';

class Row extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dialogOpen: false };
  }

  handleOpen() {
    this.setState({ dialogOpen: true });
  }

  handleClose() {
    this.setState({ dialogOpen: false });
  }

  render() {
    const { request } = this.props;
    const indicatorColor = request.state === 'open' ? RED : ORANGE;
    return (
      <tr className="Row ChangeRequestRow">
        <td>{request.createdAt}</td>
        <td>{request.author}</td>
        <td>
          <span className="state-indicator" style={{ background: indicatorColor }}></span>
          {request.stateTranslation}
        </td>
        <td>
          <Button onClick={this.handleOpen.bind(this)} className={Classes.MINIMAL} icon="eye-open" />
          <Dialog
            isOpen={this.state.dialogOpen}
            onClose={this.handleClose.bind(this)}
            title={`Änderungsantrag vom ${request.createdAt}`}
            style={{ width: '600px' }}
          >
            <div className={Classes.DIALOG_BODY}>
              <div className="message-wrapper">{request.message}</div>
              { request.attachments.length > 0 && <AttachmentsTable attachments={request.attachments} /> }
            </div>
          </Dialog>
        </td>
      </tr>
    );
  }
}

export default Row;
