import gql from 'graphql-tag';

export const GET_CHANGE_REQUESTS = gql`
  query ContractChangeRequests($id: ID!) {
    contractChangeRequests(id: $id) {
      id
      state
      stateTranslation
      author
      message
      createdAt
      attachments {
        id
        title
        url
      }
    }
  }
`;

export const CHANGE_REQUEST_MUTATION = gql`
  mutation CreateChangeRequest($contractId: ID!, $reason: String!, $text: String!, $attachments: CreateChangeRequestUploadsCollectionInput) {
    createChangeRequest(contractId: $contractId, reason: $reason, text: $text, attachments: $attachments) {
      changeRequest {
        message
        reason
      }
      error
      errors {
        attribute
        messages
      }
    }
  }
`;
