import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import {
  Button,
  Classes,
} from '@blueprintjs/core';
import LoadingIndicator from '../components/common/LoadingIndicator';
import './ProfileButton.sass';

export const GET_PARTNER_NAME = gql`
  query CurrentPartner {
    currentPartner {
      firstname
      lastname
      email
      number
    }
  }
`;

export default function ProfileButton() {
  return (
    <div className="ProfileButton">
      <Query query={GET_PARTNER_NAME}>
        {({ loading, error, data }) => {
          if (error) return <p>{`Error! ${error.message}`}</p>;
          if (loading) return <LoadingIndicator />;

          const { firstname, lastname, email, number } = data.currentPartner;
          let text = email;
          if (firstname && lastname) {
            text = [firstname, lastname].join(' ');
          }
          return <Button className={Classes.MINIMAL} icon="user">
            <span>{text}</span>
            <br/>
            <span className="partner-number">{number}</span>
          </Button>;
        }}
      </Query>
    </div>
  );
}
