import React from 'react';
import './InfoBox.sass';

export default function InfoBox({ children }) {
  return (
    <div className="InfoBox bp3-callout">
      {children}
    </div>
  );
}
