import React from 'react';
import CardComponent from '../common/CardComponent';
import { HTMLTable } from '@blueprintjs/core';
import { isPresent } from '../../utils/common';
import DataRow from '../common/DataRow';

function PersonalData({ data }) {
  return (
    <div className="PersonalData">
      <CardComponent title="Persönliche Daten">
        <HTMLTable>
          <tbody>
            <DataRow title="Anrede" value={data.sex} />
            <DataRow title="Name" value={`${data.firstname} ${data.lastname}`} />
            <DataRow title="Straße, Nr" value={`${data.street}, ${data.number}`} />
            {isPresent(data.addressAddition) &&
              <DataRow title="Adresszusatz" value={data.addressAddition} />
            }
            <DataRow title="Stadt" value={`${data.zip} ${data.city}`} />
            <DataRow title="Land" value={data.countryTitle} />
            <DataRow title="E-Mail" value={data.email} />
            <DataRow title="Telefon" value={data.telefon} />
            <DataRow title="Geburtsdatum" value={data.birthday} />
            {isPresent(data.beruf) &&
              <DataRow title="Beruf" value={data.beruf} />
            }
          </tbody>
        </HTMLTable>
      </CardComponent>
    </div>
  );
}

export default PersonalData;
