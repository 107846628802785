import React from 'react';
import './BoatImage.sass';

function BoatImage({ url }) {
  const bg = `url(${url}&size=thumb) #f5f5f5 no-repeat center center`;
  return (
    <div className="BoatImage col-xs-6 col-sm-4">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <div className="image" style={{ background: bg, backgroundSize: 'contain' }}></div>
      </a>
    </div>
  );
}

export default BoatImage;
