import React from 'react';
import { Redirect } from 'react-router-dom';

class RootContainer extends React.Component {
  render() {
    return (
      <Redirect to="/vertraege" />
    );
  }
}

export default RootContainer;
