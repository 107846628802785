import React from 'react';
import {
  Card,
  Elevation,
} from '@blueprintjs/core';

function ProvisionsOverview() {
  return (
    <Card elevation={Elevation.TWO} style={{ position: 'relative' }}>
      <p>Sie erhalten folgende Provision:</p>
      <p>
        <b>bei 3 Risiken</b> einmalig 25 € pro Sparte.<br/>
        <b>bei 4 bis 50 Risiken</b> laufend jedes Jahr 10% auf den Nettogesamtbetrag.<br/>
        <b>bei 51 bis 150 Risiken</b> laufend jedes Jahr 12,5% auf den Nettogesamtbetrag.<br/>
        <b>bei 151 bis 250 Risiken</b> laufend jedes Jahr 15% auf den Nettogesamtbetrag.<br/>
        <b>bei 251 bis 500 Risiken</b> laufend jedes Jahr 17,5% auf den Nettogesamtbetrag.<br/>
        <b>bei 501 und mehr Risiken</b> laufend jedes Jahr 20% auf den Nettogesamtbetrag.<br/>
      </p>
    </Card>
  );
}

export default ProvisionsOverview;
