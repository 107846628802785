import React from 'react';
import { HTMLTable } from '@blueprintjs/core';
import BoatImage from './BoatImage';
import './BoatImages.sass';

function BoatImages({ images }) {
  return (
    <div className="BoatImages row">
      <HTMLTable>
        <thead>
          <tr><th>Bilder</th></tr>
        </thead>
      </HTMLTable>
      { images.map(image => <BoatImage key={image.id} url={image.url} />) }
    </div>
  );
}

export default BoatImages;
