import React from 'react';
import { HTMLTable } from '@blueprintjs/core';
import DeleteButton from './DeleteButton';
import './index.sass';

function FilesUploader({ files, onChange, onDelete, multiple, accept, uploaderText, buttonText }) {
  return (
    <div className="FilesUploader">
      <p>{uploaderText || "Dokumente hochladen"}</p>
      { files.length > 0 &&
        <HTMLTable>
          <thead>
            <tr>
              <th>Dokumente</th>
            </tr>
          </thead>
          <tbody>
            { files.map((file, index) => {
              return (
                <tr key={index}>
                  <td>{file.name}</td>
                  <td><DeleteButton fileIndex={index} onClick={onDelete} /></td>
                </tr>
              );
            })}
          </tbody>
        </HTMLTable>
      }
      <input
        id="file-input"
        type="file"
        multiple={multiple === true}
        accept={accept || ""}
        onChange={onChange}
      />
      <label className="bp3-button bp3-icon-cloud-upload" htmlFor="file-input">
        {buttonText || "Hochladen"}
      </label>
    </div>
  );
}

export default FilesUploader;
