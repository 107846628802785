import gql from 'graphql-tag';
import { fromToVariables } from './utils';

export const getQueryVariablesForEvent = (event) => {
  let variables = fromToVariables();
  variables.eventName = event;
  return variables;
}

export const GET_FOUR_CHARTS_STATISTICS = gql`
  query Statistics($eventName: String!, $startOfCurrentWeek: DateTime!, $endOfCurrentWeek: DateTime!, $currentWeekResolution: String!, $startOfLastWeek: DateTime!, $endOfLastWeek: DateTime!, $lastWeekResolution: String!, $startOfCurrentMonth: DateTime!, $endOfCurrentMonth: DateTime!, $currentMonthResolution: String!, $startOfLastMonth: DateTime!, $endOfLastMonth: DateTime!, $lastMonthResolution: String!, $startOfCurrentYear: DateTime!, $endOfCurrentYear: DateTime!, $currentYearResolution: String!, $startOfLastYear: DateTime!, $endOfLastYear: DateTime!, $lastYearResolution: String!) {
    currentWeek: statistics(eventName: $eventName, from: $startOfCurrentWeek, to: $endOfCurrentWeek, resolution: $currentWeekResolution) {
      ...statisticFields
    }
    lastWeek: statistics(eventName: $eventName, from: $startOfLastWeek, to: $endOfLastWeek, resolution: $lastWeekResolution) {
      ...statisticFields
    }
    currentMonth: statistics(eventName: $eventName, from: $startOfCurrentMonth, to: $endOfCurrentMonth, resolution: $currentMonthResolution) {
      ...statisticFields
    }
    lastMonth: statistics(eventName: $eventName, from: $startOfLastMonth, to: $endOfLastMonth, resolution: $lastMonthResolution) {
      ...statisticFields
    }
    currentYear: statistics(eventName: $eventName, from: $startOfCurrentYear, to: $endOfCurrentYear, resolution: $currentYearResolution) {
      ...statisticFields
    }
    lastYear: statistics(eventName: $eventName, from: $startOfLastYear, to: $endOfLastYear, resolution: $lastYearResolution) {
      ...statisticFields
    }
  }

  fragment statisticFields on Statistic {
    eventName
    from
    to
    resolution
    eventAggregations {
      resolution
      value
      from
      to
    }
  }
`;
