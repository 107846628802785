import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import './OnlineCalculatorLink.sass';
import {
  Button,
  Dialog,
  Classes,
} from '@blueprintjs/core';
import { Responsive } from 'semantic-ui-react';

export const GET_CURRENT_PARTNER = gql`
  query CurrentPartner {
    currentPartner {
      onlineCalculatorLink
    }
  }
`;

export class OnlineCalculatorLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dialogOpen: false };
  }

  handleOpen() {
    this.setState({ dialogOpen: true });
  }

  handleClose() {
    this.setState({ dialogOpen: false });
  }

  render() {
    return (
      <Query query={GET_CURRENT_PARTNER}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return (<p>{`Error! ${error.message}`}</p>);
          return (
            <React.Fragment>
              <Responsive maxWidth={1000}>
                <Button onClick={this.handleOpen.bind(this)} icon="link" />
              </Responsive>
              <Responsive minWidth={1001}>
                <Button onClick={this.handleOpen.bind(this)} icon="link" text="Mein Partner-Link" />
              </Responsive>
              <Dialog
                isOpen={this.state.dialogOpen}
                onClose={this.handleClose.bind(this)}
                icon="link"
                title="Mein Partner-Link"
                style={{ width: '600px' }}
              >
                <div className={Classes.DIALOG_BODY}>
                  <p>
                    Verwenden Sie diesen Link auf Ihrer Website um Ihre Kunden zu unseren
                    Online-Rechner zu führen. Ihre Partner-Nummer ist dann im Antrag vorausgefüllt.
                  </p>
                  <div className="link-wrapper">
                    {data.currentPartner.onlineCalculatorLink}
                  </div>
                </div>
              </Dialog>
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}
