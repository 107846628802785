import React from 'react';
import queryString from 'query-string';
import { Query } from 'react-apollo';
import DamageRequestIframe from './DamageRequestIframe';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import { GET_NEW_DAMAGE_REQUEST_URL } from './queries';

function DamageRequest({ location }) {
  const params = queryString.parse(location.search);

  return (
    <Query query={GET_NEW_DAMAGE_REQUEST_URL}>
      {({ loading, error, data }) => {
        if (loading) return <LoadingIndicator />;
        if (error || !data || !data.currentPartner) return null;

        const url = `${data.currentPartner.damageRequestIframeUrl}?contract_id=${params.contract_id}&version=${params.version}`;

        return <DamageRequestIframe url={url} />;
      }}
    </Query>
  );
}

export default DamageRequest;
