import React from 'react';
import BackButton from './contractDetails/BackButton';
import CardComponent from './common/CardComponent';
import ChangeRequests from './contractDetails/changeRequests';
import DamageRequests from './contractDetails/damageRequests';
import PersonalData from './contractDetails/PersonalData';
import BoatData from './contractDetails/BoatData';
import PaymentData from './contractDetails/PaymentData';
import ContractComponents from './contractDetails/ContractComponents';
import './ContractDetails.sass';
import { isPresent } from '../utils/common';

function ContractDetails({ history, contract }) {
  let externalReference = contract.externalPartnerReference

  if (contract.data.partner && !isPresent(externalReference)) {
    externalReference = contract.data.partner.externalPartnerReference
  }

  return (
    <div className="ContractDetails">
      <BackButton history={history} />
      <PersonalData data={contract.data} />
      <BoatData data={contract.data} attachments={contract.attachments} />
      <PaymentData data={contract.data} />
      <ContractComponents
        components={contract.components}
        externalReference={externalReference}
      />
      <CardComponent separator title="Offene Änderungsanträge">
        <ChangeRequests contractId={contract.id} />
      </CardComponent>
      <CardComponent separator title="Schäden">
        <DamageRequests
          contractId={contract.id}
          version={contract.data.version}
        />
      </CardComponent>
    </div>
  );
}

export default ContractDetails;
