export const rootRoute = () => "/";
export const oAuthRedirect = () => "/oauth-redirect";
export const oAuthDestroyAndRedirect = () => "/oauth-destroy-and-redirect";
export const offeredProposals = () => '/angebote-und-antraege';
export const offeredProposal = () => '/angebote-und-antraege/:id';
export const offeredProposalForId = id => `${offeredProposals()}/${id}`;
export const newOfferCalculator = () => '/antragsrechner';
export const editOfferCalculator = () => `${newOfferCalculator()}/:id`;
export const editOfferCalculatorForId = id => `${newOfferCalculator()}/${id}`;
export const newDamageRequest = () => '/schadensmeldung';
