import Moment from 'moment';

export const fromToVariables = () => ({
  // current week
  startOfCurrentWeek: Moment().startOf('isoWeek').format(),
  endOfCurrentWeek: Moment().endOf('isoWeek').format(),
  currentWeekResolution: 'day',
  // last week
  startOfLastWeek: Moment().subtract(1, 'weeks').startOf('isoWeek').format(),
  endOfLastWeek: Moment().subtract(1, 'weeks').endOf('isoWeek').format(),
  lastWeekResolution: 'day',
  // current month
  startOfCurrentMonth: Moment().startOf('month').format(),
  endOfCurrentMonth: Moment().endOf('month').format(),
  currentMonthResolution: 'day',
  // last month
  startOfLastMonth: Moment().subtract(1, 'months').startOf('month').format(),
  endOfLastMonth: Moment().subtract(1, 'months').endOf('month').format(),
  lastMonthResolution: 'day',
  // current year
  startOfCurrentYear: Moment().startOf('year').format(),
  endOfCurrentYear: Moment().endOf('year').format(),
  currentYearResolution: 'month',
  // last year
  startOfLastYear: Moment().subtract(1, 'years').startOf('year').format(),
  endOfLastYear: Moment().subtract(1, 'years').endOf('year').format(),
  lastYearResolution: 'month',
});

export const DAYS = [
  'Mo',
  'Di',
  'Mi',
  'Do',
  'Fr',
  'Sa',
  'So',
];

export const MONTHS = [
  'Jan',
  'Feb',
  'Mär',
  'Apr',
  'Mai',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Okt',
  'Nov',
  'Dez',
];

export const stepSizeForDataset = (data) => {
  if (!Array.isArray(data)) return 0;
  if (data.some(isNaN)) return 0;

  return Math.max(...data) < 10 ? 1 : 0;
};
