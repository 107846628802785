import React from 'react';
import { Table } from 'semantic-ui-react';
import ContractRow from './ContractRow';
import './ContractsTable.sass';

function ContractsTable({ indexView, contracts }) {
  indexView = indexView === true;
  return (
    <Table size='small' className="ContractsTable">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>#</Table.HeaderCell>
          <Table.HeaderCell>Angelegt am</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Nachname</Table.HeaderCell>
          <Table.HeaderCell>Vorname</Table.HeaderCell>
          <Table.HeaderCell>Adresse</Table.HeaderCell>
          <Table.HeaderCell>Boot</Table.HeaderCell>
          <Table.HeaderCell>Gesamtpreis</Table.HeaderCell>
          { indexView && <Table.HeaderCell></Table.HeaderCell> }
        </Table.Row>
      </Table.Header>
      <Table.Body>
        { contracts.map(c => <ContractRow key={c.id} indexView={indexView} contract={c} />) }
      </Table.Body>
    </Table>
  );
}

export default ContractsTable;
