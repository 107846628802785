import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Icon } from '@blueprintjs/core';
import { deleteAccessToken } from '../utils/oauthClient';

export const GET_PARTNER_LOGOUT_URL = gql`
  query CurrentPartner {
    currentPartner {
      logoutUrl
    }
  }
`;

export default function Logout() {
  return (
    <Query query={GET_PARTNER_LOGOUT_URL}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return <p>{`Error! ${error.message}`}</p>;

        const onLogoutClick = event => {
          event.preventDefault();
          deleteAccessToken();
          window.location = data.currentPartner.logoutUrl;
        }

        return (
          <li onClick={onLogoutClick}>
            <span>Abmelden</span>
            <Icon icon="log-out" />
          </li>
        );
      }}
    </Query>
  );
}
