import React from 'react';
import { HTMLTable } from '@blueprintjs/core';
import CardComponent from '../../components/common/CardComponent';
import DataRow from '../../components/common/DataRow';

function GeneralCard({ data }) {
  return (
    <CardComponent title="Allgemeine Daten">
      <div className="row">
        <HTMLTable>
          <tbody>
            <DataRow title="Fahrzeugtyp" value={data.fahrzeugtyp} />
            <DataRow title="Fahrgebiet" value={data.fahrgebiet} />
            <DataRow title="Baujahr" value={data.baujahr} />
            <DataRow title="Gewünschtes Zahlungsintervall" value={data.paymentPeriod} />
          </tbody>
        </HTMLTable>
      </div>
    </CardComponent>
  );
}

export default GeneralCard;
