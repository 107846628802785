import React from 'react';
import { Mutation } from 'react-apollo';
import {
  Button,
  Alert,
  Intent,
} from '@blueprintjs/core';
import { DELETE_PARTNER_API_CLIENT } from './queries';

class DeleteWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.state = { alertIsOpen: false };
  }

  openAlert() {
    this.setState({ alertIsOpen: true });
  }

  closeAlert() {
    this.setState({ alertIsOpen: false });
  }

  handleResponse({ data: { deletePartnerApiClient } }) {
    if (deletePartnerApiClient.success) {
      this.props.refetchWebsites();
    }
  }

  render() {
    return (
      <Mutation mutation={DELETE_PARTNER_API_CLIENT}>
        {(deletePartnerApiClient, { loading }) => (
          <React.Fragment>
            <Button small
              loading={loading}
              icon="trash"
              onClick={this.openAlert.bind(this)}
            />
            <Alert
              icon="trash"
              intent={Intent.DANGER}
              isOpen={this.state.alertIsOpen}
              cancelButtonText="Abbrechen"
              onCancel={this.closeAlert.bind(this)}
              confirmButtonText="Webseite löschen"
              onConfirm={() => {
                deletePartnerApiClient({
                  variables: { id: this.props.id },
                }).then(this.handleResponse.bind(this));
              }}
            >
              <p>Wollen Sie diese Webseite wirklich löschen?</p>
            </Alert>
          </React.Fragment>
        )}
      </Mutation>
    );
  }
}

export default DeleteWebsite;
