import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { Button } from '@blueprintjs/core';
import { offeredProposalForId } from '../../routes';
import DeleteOffer from './DeleteOffer';
import './OfferedProposalsTable.sass';

function OfferedProposalsTable({ proposals, refetchProposals }) {
  return (
    <Table size='small' className="OfferedProposalsTable">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Angebotsnummer</Table.HeaderCell>
          <Table.HeaderCell>Erstellt am</Table.HeaderCell>
          <Table.HeaderCell>E-Mail</Table.HeaderCell>
          <Table.HeaderCell>Bootstyp</Table.HeaderCell>
          <Table.HeaderCell>Gesamtwert</Table.HeaderCell>
          <Table.HeaderCell>Versicherungsschutz</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {proposals.map(proposal => <ProposalRow
          key={proposal.id}
          proposal={proposal}
          refetchProposals={refetchProposals}
        />)}
      </Table.Body>
    </Table>
  );
}

function ProposalRow({ proposal, refetchProposals }) {
  return (
    <Table.Row>
      <Table.Cell>{`VS-${proposal.id}`}</Table.Cell>
      <Table.Cell>{proposal.formattedCreatedAt}</Table.Cell>
      <Table.Cell>{proposal.clientEmail}</Table.Cell>
      <Table.Cell>{proposal.data.fahrzeugtyp}</Table.Cell>
      <Table.Cell>{proposal.data.boatTotalValue}</Table.Cell>
      <Table.Cell>
        {proposal.data.sortedComponents.length > 0 &&
          <ul>
            {proposal.data.sortedComponents.map(c => <li key={c.id}>{c.title}</li>)}
          </ul>
        }
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Link to={offeredProposalForId(proposal.id)}>
          <Button icon="eye-open" />
        </Link>
        <DeleteOffer
          id={proposal.id}
          refetchProposals={refetchProposals}
        />
      </Table.Cell>
    </Table.Row>
  );
}

export default OfferedProposalsTable;
