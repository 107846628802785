import React from 'react';
import { Query } from 'react-apollo';
import {
  Card,
  Elevation,
} from '@blueprintjs/core';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import ContractsPagination from '../../components/ContractsPagination';
import OfferedProposalsTable from './OfferedProposalsTable';
import { GET_OFFERED } from './queries';

function OfferedProposalsQuery({ per, page, offset, toPrev, toNext }) {
  return (
    <Query query={GET_OFFERED} variables={{ offset, limit: per }} fetchPolicy="network-only">
      {({ loading, error, data, refetch }) => {
        data = (data && data.partnerOfferedCollection) || {};
        if (error) return (<p>{`Error! ${error.message}`}</p>);
        const count = data.count || 0;

        return (
          <div>
            <Card elevation={Elevation.TWO} style={{ position: 'relative', padding: '5px' }}>
              { loading && <LoadingIndicator /> }
              { (data.nodes && data.nodes.length > 0) ? (
                <OfferedProposalsTable
                  proposals={data.nodes}
                  refetchProposals={refetch}
                />
              ) : (
                <div className="empty-message bp3-callout">
                  <p>Noch keine Daten Angebote oder Anträge</p>
                </div>
              )}
            </Card>
            { count > per &&
              <ContractsPagination
                current={page}
                last={Math.ceil(count / per)}
                onPrev={toPrev}
                onNext={toNext}
              />
            }
          </div>
        );
      }}
    </Query>
  );
}

export default OfferedProposalsQuery;
