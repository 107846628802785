import React from 'react';
import { Mutation } from 'react-apollo';
import { Table } from 'semantic-ui-react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { rainbow } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import DeleteWebsite from './DeleteWebsite';
import DefaultBoatTypeSelect from './DefaultBoatTypeSelect';
import LoadingIndicator from '../common/LoadingIndicator';
import { UPDATE_PARTNER_API_CLIENT } from './queries';
import './WebsiteRow.sass';

function WebsiteRow({ apiClient, refetchWebsites }) {
  return (
    <React.Fragment>
      <Table.Row className="WebsiteRow">
        <Table.Cell><b>{apiClient.origin}</b></Table.Cell>
        <Table.Cell>
          <DeleteWebsite
            id={apiClient.id}
            refetchWebsites={refetchWebsites}
          />
        </Table.Cell>
      </Table.Row>
      <Table.Row className="AccessRow">
        <td colSpan="3" className="WebsiteAccessId">
          <p>Um den Online-Rechner auf der Website einzubinden, muss der Code innerhalb des <code>{'<body>'}</code> an die Stelle kopiert werden, an der Sie den Online-Rechner anzeigen möchten.</p>
          <SyntaxHighlighter language='xml' style={rainbow}>
              {`<script>
  !function(e,t,a,r,X){var n,c=t.getElementsByTagName(a)[0];t.getElementById(r)||((n=t.createElement(a)).id=r,n.src="https://cdn.nammert.com/index.js",c.parentNode.insertBefore(n,c),e.nammertCalculatorInit={selector:".nammert-calculator",accessId:"${apiClient.accessId}"},e.nammertCalculator&&nammertCalculator(e.nammertCalculatorInit))}(window,document,"script","nammert-calculator-script");
</script>
<div class="nammert-calculator"></div>`}
            </SyntaxHighlighter>
        </td>
      </Table.Row>
      <Table.Row className="DefaultBoatTypeRow">
        <td colSpan="3">
          Vorauswahl Fahrzeugtyp:{' '}
          <Mutation mutation={UPDATE_PARTNER_API_CLIENT}>
            {(updatePartnerApiClient, { loading, error }) => {
              if (loading) return <LoadingIndicator />;
              if (error) return <p>{`Error! ${error.message}`}</p>;

              const onChange = e => {
                updatePartnerApiClient({
                  variables: {
                    id: apiClient.id,
                    attributes: {
                      defaultBoatType: e.target.value,
                    },
                  },
                });
              };

              return (
                <DefaultBoatTypeSelect
                  value={apiClient.defaultBoatType}
                  onChange={onChange}
                />
              );
            }}
          </Mutation>
        </td>
      </Table.Row>
    </React.Fragment>
  );
}

export default WebsiteRow;
