import React from 'react';
import { ApolloProvider } from "react-apollo";
import {
  Route,
  Switch,
  Router,
} from 'react-router-dom';
import history from './utils/history';
import {
  rootRoute,
  oAuthRedirect,
  oAuthDestroyAndRedirect,
} from './routes';
import OauthRedirect from './containers/OauthRedirect';
import OauthDestroyAndRedirect from './containers/OauthDestroyAndRedirect';
import MainRouter from './containers/MainRouter';
import apolloClient from './utils/apolloClient';

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <Router history={history}>
        <Switch>
          <Route exact path={oAuthRedirect()} component={OauthRedirect} />
          <Route exact path={oAuthDestroyAndRedirect()} component={OauthDestroyAndRedirect} />
          <Route path={rootRoute()} component={MainRouter} />
        </Switch>
      </Router>
    </ApolloProvider>
  );
}

export default App;
