import React from 'react';
import { HTMLTable } from '@blueprintjs/core';
import CardComponent from '../common/CardComponent';
import DataRow from '../common/DataRow';
import TrailerData from './TrailerData';
import BoatImages from './BoatImages';
import Attachments from './Attachments';
import { yesOrNo } from '../../utils/common';
import './BoatData.sass';

function BoatData({ data, attachments }) {
  return (
    <div className="BoatData">
      <CardComponent title="Boot Daten">
        <div className="row">
          <HTMLTable>
            <tbody>
              <DataRow title="Nutzen" value={data.boatUsage} />
              <DataRow title="Teilnahme Regatten" value={yesOrNo(data.bootTeilnahmeRegatten)} />
              <DataRow title="Eigenbau" value={yesOrNo(data.bootEigenbau)} />
              <DataRow title="Rumpfmaterial" value={data.bootRumpfmaterial} />
              <DataRow title="Beschreibung Rumpfmaterial" value={data.bootRumpfmaterialBeschreibung} />
              <DataRow title="Mast-Typ" value={data.bootMastType} />
              <DataRow title="Bootstyp" value={data.bootVerdraenger} />
              <DataRow title="Kennzeichen" value={data.bootKennzeichen} />
              <DataRow title="Baunummer" value={data.bootBaunummer} />
              <DataRow title="Motornummer" value={data.bootMotornummer} />
              <DataRow title="Name" value={data.bootName} />
              <DataRow title="Hersteller" value={data.bootHersteller} />
              <DataRow title="Modell" value={data.bootModel} />
              <DataRow title="VMax über 100 km/h" value={yesOrNo(data.bootFaster100KmPerHour)} />
              <DataRow title="Geschwindigkeit" value={data.bootSpeed} />
              <DataRow title="Länge" value={data.bootLaenge} addon="m" />
              <DataRow title="Breite" value={data.bootBreite} addon="m" />
              <DataRow title="Tiefgang" value={data.bootTiefgang} addon="m" />
            </tbody>
          </HTMLTable>
        </div>
        { data.beiboot_available &&
          <div className="row">
            <HTMLTable>
              <thead>
                <tr><th>Beiboot</th></tr>
              </thead>
              <tbody>
                <DataRow title="Beiboot Wert" value={data.wertBeiboot} />
                <DataRow title="Beiboot Hersteller" value={data.beibootHersteller} />
                <DataRow title="Beiboot Kennzeichen" value={data.beibootKennzeichen} />
                <DataRow title="Beiboot Motorleistung" value={data.beibootMotorleistung} addon=" PS" />
                <DataRow title="Beiboot Motornummer" value={data.beibootMotornummer} />
                <DataRow title="Beiboot Baunummer" value={data.beibootBaunummer} />
                <DataRow title="Beiboot Modell" value={data.beibottModel} />
                <DataRow title="Beiboot Baujahr" value={data.beibootBaujahr} />
                <DataRow title="Beiboot Motorhersteller" value={data.beibootMotorhersteller} />
                <DataRow title="Beiboot Motorbaujahr" value={data.beibootMotorbaujahr} />
              </tbody>
            </HTMLTable>
          </div>
        }
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <HTMLTable>
              <thead>
                <tr><th>Liegeplatz Sommer</th></tr>
              </thead>
              <tbody>
                <DataRow title="Land" value={data.liegeplatzSommerCountry} />
                <DataRow title="Stadt" value={data.liegeplatzSommerCity} />
                <DataRow title="Marina" value={data.liegeplatzSommerMarina} />
              </tbody>
            </HTMLTable>
          </div>
          <div className="col-xs-12 col-sm-6">
            <HTMLTable>
              <thead>
                <tr><th>Liegeplatz Winter</th></tr>
              </thead>
              <tbody>
                <DataRow title="Land" value={data.liegeplatzWinterCountry} />
                <DataRow title="Stadt" value={data.liegeplatzWinterCity} />
                <DataRow title="Marina" value={data.liegeplatzWinterMarina} />
              </tbody>
            </HTMLTable>
          </div>
        </div>
        { data.trailer_required && <TrailerData data={data} /> }
        { data.images.length > 0 && <BoatImages images={data.images} /> }
        { attachments.length > 0 && <Attachments attachments={attachments} /> }
      </CardComponent>
    </div>
  );
}

export default BoatData;
