import gql from 'graphql-tag';

export const GET_DAMAGE_REQUESTS = gql`
  query ContractDamageRequests($id: ID!) {
    contractDamageRequests(id: $id) {
      id
      state
      stateTranslation
      author
      message
      createdAt
      isOld
      attachments {
        id
        title
        url
      }
    }
  }
`;
