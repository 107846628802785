import React from 'react';
import { Button } from '@blueprintjs/core';

function ReadOnlyValue({ value, editCallback }) {
  let td = <td>{value}</td>;
  if (!value) {
    td = <td className="not-available">nicht verfügbar</td>;
  }

  return (
    <React.Fragment>
      {td}
      <td className="actions">
        <Button icon="edit" onClick={editCallback} />
      </td>
    </React.Fragment>
  );
}

export default ReadOnlyValue;
