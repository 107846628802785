import React from 'react';
import './Separator.sass';

function Separator() {
  return (
    <div className="Separator"></div>
  );
}

export default Separator;
