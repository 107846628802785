import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import LoadingIndicator from '../components/common/LoadingIndicator';
import PartnerData from '../components/partnerData';

export const GET_CURRENT_PARTNER = gql`
  query CurrentPartner {
    currentPartner {
      number
      firstname
      lastname
      company
      contactEmail
      phone
      website
      street
      streetNumber
      addressAddition
      zip
      city
      country
      iban
      bic
      errors {
        attribute
        messages
      }
    }
  }
`;

class PartnerDataContainer extends React.Component {
  render() {
    return (
      <Query query={GET_CURRENT_PARTNER}>
        {({ loading, error, data }) => {
          if (loading) return <LoadingIndicator />;
          if (error) return (<p>{`Error! ${error.message}`}</p>);
          return <PartnerData partnerData={data.currentPartner} />;
        }}
      </Query>
    );
  }
}

export default PartnerDataContainer;
