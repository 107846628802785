import gql from 'graphql-tag';
import apolloClient from './apolloClient';

export const changePassword = (credentials) => {
  return apolloClient.mutate({
    mutation: gql`
      mutation ChangePassword($credentials: ChangePasswordInput!) {
        changePartnerPassword(credentials: $credentials) {
          success
          errors {
            attribute
            messages
          }
        }
      }
    `,
    variables: { credentials }
  })
    .then(({ data }) => {
      const success = data.changePartnerPassword && data.changePartnerPassword.success;
      const errors = data.changePartnerPassword && data.changePartnerPassword.errors;
      return { success, errors };
    })
    .catch(error => console.error(error));
}
