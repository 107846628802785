import React from 'react';
import CardComponent from '../../common/CardComponent';
import InfoBox from '../InfoBox';
import MainQuery from './MainQuery';

export default function CalculatedOffersCard() {
  return (
    <CardComponent titleH4 separator title='Anzahl Preiskalkulationen'>
      <InfoBox>
        <p>
          Die Statistik zeigt die Anzahl der Preiskalkulationen in den Online-Rechnern
          (Aufruf der zweiten Seite). Alle Kalkulationen in Ihren Online-Rechnern werden
          erfasst. Ebenfalls werden alle Kalkulationen im nammert.com Online-Rechner erfasst,
          von Besuchern die sie geworben haben.
        </p>
      </InfoBox>
      <MainQuery />
    </CardComponent>
  );
}
