import React from 'react';
import queryString from 'query-string';
import { Contracts } from './Contracts';

function ContractsContainer({ location, history }) {
  const PER = 25;
  const params = queryString.parse(location.search);
  const page = parseInt(params.page || '1', 10) || 1;

  const navigateTo = (p) => history.push(`/vertraege?page=${p}`);
  const toPrev = () => navigateTo(page - 1);
  const toNext = () => navigateTo(page + 1);

  return (
    <Contracts
      per={PER}
      page={page}
      offset={page > 1 ? ((page - 1) * PER) : 0}
      toPrev={toPrev}
      toNext={toNext}
    />
  );
}

export default ContractsContainer;
