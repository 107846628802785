import React from 'react';
import CardComponent from '../common/CardComponent';
import { HTMLTable } from '@blueprintjs/core';
import DataRow from '../common/DataRow';

function PaymentData({ data }) {
  return (
    <div className="PaymentData">
      <CardComponent title="Vertragsbeginn & Zahlungsdaten">
        <HTMLTable>
          <tbody>
            <DataRow title="Vertragsbeginn" value={data.vertragsbeginn} />
            <DataRow title="Zahlungsart" value={data.sepa} />
            <DataRow title="Kontoinhaber" value={data.ddAccountOwner} />
            <DataRow title="Inhaber-Adresse" value={data.ddAccountAddress} />
            <DataRow title="BIC" value={data.ddBankCode} />
            <DataRow title="IBAN" value={data.ddAccountNo} />
          </tbody>
        </HTMLTable>
      </CardComponent>
    </div>
  );
}

export default PaymentData;
