import React from 'react';
import { Query } from 'react-apollo';
import CalculatorIFrame from './CalculatorIFrame';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import { GET_EDIT_OFFER_URL } from './queries';

function EditOffer({ match }) {
  return (
    <Query query={GET_EDIT_OFFER_URL} variables={{ offerId: parseInt(match.params.id, 10) }}>
      {({ loading, error, data }) => {
        if (loading) return <LoadingIndicator />;
        if (error || !data || !data.currentPartner) return null;

        return <CalculatorIFrame url={data.currentPartner.editOfferApiUrl} />;
      }}
    </Query>
  );
}

export default EditOffer;
