import React from 'react';
import { Query } from 'react-apollo';
import { GET_CALCULATED_OFFERS_STATISTICS, getQueryVariables } from './queries';
import { DAYS, MONTHS } from '../utils';
import NoStatisticsMessage from '../NoStatisticsMessage';
import LoadingIndicator from '../../common/LoadingIndicator';
import BarsChart from '../BarsChart';

function MainQuery() {
  return (
    <Query query={GET_CALCULATED_OFFERS_STATISTICS} variables={getQueryVariables()}>
      {({ loading, error, data }) => {
        if (loading) return <LoadingIndicator />;
        if (error) return (<p>{`Error! ${error.message}`}</p>);

        const valuesOf = (aggregations) => {
          return aggregations.map(a => { return a.value });
        }

        const currentWeekData = {
          set1: valuesOf(data.currentWeekPartnerLink.eventAggregations),
          set2: valuesOf(data.currentWeekOnlineCalculator.eventAggregations),
        };

        const lastWeekData = {
          set1: valuesOf(data.lastWeekPartnerLink.eventAggregations),
          set2: valuesOf(data.lastWeekOnlineCalculator.eventAggregations),
        };

        const currentMonthData = {
          set1: valuesOf(data.currentMonthPartnerLink.eventAggregations),
          set2: valuesOf(data.currentMonthOnlineCalculator.eventAggregations),
        };

        const lastMonthData = {
          set1: valuesOf(data.lastMonthPartnerLink.eventAggregations),
          set2: valuesOf(data.lastMonthOnlineCalculator.eventAggregations),
        };

        const currentYearData = {
          set1: valuesOf(data.currentYearPartnerLink.eventAggregations),
          set2: valuesOf(data.currentYearOnlineCalculator.eventAggregations),
        };

        const lastYearData = {
          set1: valuesOf(data.lastYearPartnerLink.eventAggregations),
          set2: valuesOf(data.lastYearOnlineCalculator.eventAggregations),
        };

        const allValues = [].concat(
          currentWeekData.set1,
          currentWeekData.set2,
          lastWeekData.set1,
          lastWeekData.set2,
          currentMonthData.set1,
          currentMonthData.set2,
          lastMonthData.set1,
          lastMonthData.set2,
          currentYearData.set1,
          currentYearData.set2,
          lastYearData.set1,
          lastYearData.set2,
        );

        if (allValues.reduce((a, b) => a + b, 0) === 0) {
          return <NoStatisticsMessage />;
        }

        return (
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <h4>Letzte Woche</h4>
              <BarsChart data={lastWeekData} labels={DAYS} />
            </div>
            <div className="col-xs-12 col-sm-6">
              <h4>Diese Woche</h4>
              <BarsChart data={currentWeekData} labels={DAYS} />
            </div>
            <div className="col-xs-12 col-sm-6">
              <h4>Letzter Monat</h4>
              <BarsChart data={lastMonthData} />
            </div>
            <div className="col-xs-12 col-sm-6">
              <h4>Dieser Monat</h4>
              <BarsChart data={currentMonthData} />
            </div>
            <div className="col-xs-12 col-sm-6">
              <h4>Letztes Jahr</h4>
              <BarsChart data={lastYearData} labels={MONTHS} />
            </div>
            <div className="col-xs-12 col-sm-6">
              <h4>Dieses Jahr</h4>
              <BarsChart data={currentYearData} labels={MONTHS} />
            </div>
          </div>
        );
      }}
    </Query>
  );
}

export default MainQuery;
