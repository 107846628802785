import React from 'react';
import { isPresent } from '../../utils/common';
import './DataRow.sass';

function DataRow({ title, value, addon }) {
  let content = <td className="not-available">nicht verfügbar</td>;
  if (isPresent(value)) {
    content = <td>{`${value}${addon || ''}`}</td>;
  }

  return (
    <tr className="DataRow">
      <td>{title}:</td>
      {content}
    </tr>
  );
}

export default DataRow;
