import React from 'react';
import { Query } from 'react-apollo';
import { GET_BOAT_TYPES } from './queries';

function DefaultBoatTypeSelect({ value, onChange }) {
  return (
    <Query query={GET_BOAT_TYPES}>
      {({ loading, data }) => {
        if (loading) return null;

        return (
          <select value={value || ''} onChange={onChange}>
            {data.boatTypes.map(boatType => (
              <option key={boatType.value} value={boatType.value}>{boatType.title}</option>
            ))}
          </select>
        );
      }}
    </Query>
  );
}

export default DefaultBoatTypeSelect;
