import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import gql from 'graphql-tag';
import apolloClient from '../utils/apolloClient';
import ToggleButton from './ToggleButton';
import Logout from './Logout';
import { offeredProposals } from '../routes';
import './Sidebar.sass';

export const GET_PARTNER_ACCOUNT_TYPE = gql`
  query CurrentPartner {
    currentPartner {
      parentPartnerAccountType
    }
  }
`;

const basicMenuItems = [
  { href: offeredProposals(), icon: 'document', text: 'Angebote & Anträge' },
  { href: '/vertraege', icon: 'saved', text: 'Verträge' },
  { href: '/meine-daten', icon: 'cog', text: 'Meine Daten' },
  { href: '/meine-online-rechner', icon: 'calculator', text: 'Meine Online-Rechner' },
  { href: '/statistiken', icon: 'timeline-line-chart', text: 'Statistiken' },
]

const provisionMenuItem = { href: '/provisionen', icon: 'euro', text: 'Provisionen' }

function Sidebar({ open, toggleable, toggleSidebar, location }) {
  const [menuItems, setMenuItems] = useState(basicMenuItems);

  useEffect(() => {
    const query = apolloClient.query({ query: GET_PARTNER_ACCOUNT_TYPE })

    if (!query) return

    query.then((result) => {
      const { data } = result

      if (!data || !data.currentPartner) return

      const items = [...basicMenuItems]
      const accountType = data.currentPartner.parentPartnerAccountType

      if (!accountType || accountType === 'provision_split') {
        items.splice(1, 0, provisionMenuItem)
        setMenuItems(items)
      }
    })
  }, [setMenuItems])

  return (
    <aside className={`Sidebar ${ open ? 'sidebar-open' : '' }`}>
      <div className="sidebar-header">
        { toggleable && <ToggleButton onToggleButtonClick={toggleSidebar} /> }
        <div className="logo"></div>
      </div>
      <ul>
        {menuItems.map(item => {
          return (
            <li key={item.href} className={location.pathname.includes(item.href) ? 'active' : ''} onClick={toggleSidebar}>
              <Link to={item.href}>
                <span>{item.text}</span>
                <Icon icon={item.icon} />
              </Link>
            </li>
          );
        })}
      </ul>
      <ul className="bottom">
        <Logout />
      </ul>
    </aside>
  );
}

export default withRouter(Sidebar);
