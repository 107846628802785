import React from 'react';
import {
  Card,
  H5,
  Elevation,
  FormGroup,
  InputGroup,
  Callout,
  Intent,
  Button,
} from '@blueprintjs/core';
import Separator from './common/Separator';
import './Profile.sass';

function Profile({
  currentPassword,
  password,
  passwordConfirmation,
  success,
  onChange,
  onSubmit,
}) {
  return (
    <div className="Profile">
      <Card className="profile-card" elevation={Elevation.TWO}>
        <H5>Profil</H5>
        <Separator />
        <form>
          <FormField
            label="Aktuelles Passwort"
            name="currentPassword"
            data={currentPassword}
            onChange={onChange}
          />
          <FormField
            label="Neues Passwort"
            name="password"
            data={password}
            onChange={onChange}
          />
          <FormField
            label="Neues Passwort nochmal"
            name="passwordConfirmation"
            data={passwordConfirmation}
            onChange={onChange}
          />
          { success &&
            <Callout className="success-message" intent={Intent.SUCCESS}>
              <p>Passwort wurde erfolgreich geändert</p>
            </Callout>
          }
          <Button
            type="submit"
            onClick={onSubmit}
            intent={Intent.PRIMARY}
          >
            Passwort ändern
          </Button>
        </form>
      </Card>
    </div>
  );
}

function FormField({ label, name, data, onChange }) {
  const id = `${name}-field`;
  const errors = data.errors.join(', ');
  const intent = errors.length > 0 ? Intent.DANGER : Intent.NONE;
  return (
    <FormGroup
      label={label}
      labelFor={id}
      helperText={errors}
      intent={intent}
    >
      <InputGroup
        id={id}
        leftIcon="key"
        placeholder="********"
        type="password"
        value={data.value || ''}
        onChange={onChange}
        name={name}
        intent={intent}
      />
    </FormGroup>
  );
}

export default Profile;
