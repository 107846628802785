import React from 'react';
import ReadOnlyValue from './ReadOnlyValue';
import Input from './Input';
import './Row.sass';

class Row extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editMode: false };

    this.showEditMode = this.showEditMode.bind(this);
    this.hideEditMode = this.hideEditMode.bind(this);
  }

  showEditMode() {
    this._setEditMode(true);
  }

  hideEditMode() {
    this._setEditMode(false);
  }

  _setEditMode(value) {
    this.setState({ editMode: value });
  }

  render() {
    let data = <ReadOnlyValue value={this.props.value} editCallback={this.showEditMode} />;
    if (this.state.editMode) {
      data = <Input {...this.props} closeCallback={this.hideEditMode} />;
    }
    return (
      <tr className="MyDataRow">
        <td>{this.props.title}:</td>
        {data}
      </tr>
    );
  }
}

export default Row;
