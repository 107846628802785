import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import { ApolloLink, Observable } from 'apollo-link';
import {
  getAccessToken,
  renewAccessToken,
  isPartnerTrackingDisabled,
} from './oauthClient';

const request = (operation) => {
  operation.setContext({
    headers: {
      Authorization: "Bearer " + (getAccessToken()),
      "X-Disable-Partner-Tracking": isPartnerTrackingDisabled(),
    }
  });
};

const requestLink = new ApolloLink((operation, forward) =>
  new Observable(observer => {
    let handle;
    Promise.resolve(operation)
      .then(oper => request(oper))
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));

    return () => {
      if (handle) handle.unsubscribe();
    };
  })
);

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (networkError && networkError.statusCode === 401 && networkError.result && networkError.result.error === 'ouath_token_invalid') {
        renewAccessToken();
        return;
      }

      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
        );
      if (networkError) console.error(`[Network error]: ${networkError}`);
    }),
    requestLink,
    createUploadLink({
      uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    })
  ]),
  cache: new InMemoryCache()
});

export default client;
