import React from 'react';
import './NoStatisticsMessage.sass';

export default function NoStatisticsMessage() {
  return (
    <div className="NoStatisticsMessage bp3-callout">
      <p>Noch keine Daten verfügbar</p>
    </div>
  );
}
