import React from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { Button } from '@blueprintjs/core';
import OfferedProposalsQuery from './OfferedProposalsQuery';
import {
  newOfferCalculator,
  offeredProposals,
} from '../../routes';
import './OfferedProposals.sass';

function OfferedProposals({ location, history }) {
  const PER = 50;
  const params = queryString.parse(location.search);
  const page = parseInt(params.page || '1', 10) || 1;

  const navigateTo = (p) => history.push(`${offeredProposals()}?page=${p}`);
  const toPrev = () => navigateTo(page - 1);
  const toNext = () => navigateTo(page + 1);

  return (
    <div className="OfferedProposals">
      <div className="button-wrapper">
        <Link to={newOfferCalculator()}>
          <Button icon="calculator" text="Angebot oder Antrag erstellen" />
        </Link>
      </div>
      <OfferedProposalsQuery
        per={PER}
        page={page}
        offset={page > 1 ? ((page - 1) * PER) : 0}
        toPrev={toPrev}
        toNext={toNext}
      />
    </div>
  );
}

export default OfferedProposals;
