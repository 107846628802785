import React from 'react';
import {
  H4,
  H5,
  Card,
  Elevation,
} from '@blueprintjs/core';
import Separator from './Separator';
import './CardComponent.sass';

function CardComponent({ title, separator, children, titleH4, rightTitle }) {
  return (
    <Card className="CardComponent" elevation={Elevation.TWO}>
      { titleH4 ? <H4>{ title }</H4> : <H5>{ title }</H5> }
      { rightTitle && <span className="right-title">{rightTitle}</span> }
      { separator && <Separator /> }
      { children }
    </Card>
  );
}

export default CardComponent;
