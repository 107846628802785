import React from 'react';
import { Query } from 'react-apollo';
import { HTMLTable } from '@blueprintjs/core';
import { GET_DAMAGE_REQUESTS } from './queries';
import LoadingIndicator from '../../common/LoadingIndicator';
import Row from './Row';
import CreateButton from './CreateButton';
import { newDamageRequest } from '../../../routes';
import './index.sass';

function DamageRequests({ contractId, version }) {
  return (
    <Query query={GET_DAMAGE_REQUESTS} variables={{ id: contractId }}>
      {({ loading, error, data }) => {
        if (loading) return <LoadingIndicator />;
        if (error) return (<p>{`Error! ${error.message}`}</p>);
        data = (data && data.contractDamageRequests) || [];

        let cardContent = <HTMLTable>
          <thead>
            <tr>
              <th>Hinterlegt am</th>
              <th>Erstellt von</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map(request => <Row key={request.id} request={request} />)}
          </tbody>
        </HTMLTable>;

        if (data.length === 0) cardContent = <p>Keine Schadensmeldungen</p>;

        return (
          <div className="DamageRequests">
            <div className="create-button-wrapper">
              <CreateButton
                linkTo={`${newDamageRequest()}?contract_id=${contractId}&version=${version}`}
              />
            </div>
            {cardContent}
          </div>
        );
      }}
    </Query>
  );
}

export default DamageRequests;
