import React from 'react';

export default function InfoBoxGrey() {
  return (
    <div className="bp3-callout">
      <h5 className="bp3-heading">Welche Vorteile hat ein Nammert Online-Rechner auf meiner Website?</h5>
      Nur mit einem eigenen Online-Rechner können sie alle Vorteile Ihres Partner Programms
      nutzen.
      <br/>
      <br/>
      Verwendet ein Besucher auf Ihre Website den eingebundenen Online-Rechner und erstellt einen
      Versicherungsvorschlag, dann ist Ihre Partnernummer automatisch hinterlegt und kann nicht
      mehr gelöscht werden.
    </div>
  );
}
