import gql from 'graphql-tag';
import {
  EXTERNAL_CALCULATOR_CALCULATED_OFFER,
  LOCAL_CALCULATOR_CALCULATED_OFFER,
} from '../statisticEventsNames';
import { fromToVariables } from '../utils';

export const getQueryVariables = () => {
  let variables = fromToVariables();
  variables.partnerLinkEventName = LOCAL_CALCULATOR_CALCULATED_OFFER;
  variables.onlineCalculatorEventName = EXTERNAL_CALCULATOR_CALCULATED_OFFER;
  return variables;
}

export const GET_CALCULATED_OFFERS_STATISTICS = gql`
  query Statistics($partnerLinkEventName: String!, $onlineCalculatorEventName: String!, $startOfCurrentWeek: DateTime!, $endOfCurrentWeek: DateTime!, $currentWeekResolution: String!, $startOfLastWeek: DateTime!, $endOfLastWeek: DateTime!, $lastWeekResolution: String!, $startOfCurrentMonth: DateTime!, $endOfCurrentMonth: DateTime!, $currentMonthResolution: String!, $startOfLastMonth: DateTime!, $endOfLastMonth: DateTime!, $lastMonthResolution: String!, $startOfCurrentYear: DateTime!, $endOfCurrentYear: DateTime!, $currentYearResolution: String!, $startOfLastYear: DateTime!, $endOfLastYear: DateTime!, $lastYearResolution: String!) {
    currentWeekPartnerLink: statistics(eventName: $partnerLinkEventName, from: $startOfCurrentWeek, to: $endOfCurrentWeek, resolution: $currentWeekResolution) {
      ...statisticFields
    }
    currentWeekOnlineCalculator: statistics(eventName: $onlineCalculatorEventName, from: $startOfCurrentWeek, to: $endOfCurrentWeek, resolution: $currentWeekResolution) {
      ...statisticFields
    }
    lastWeekPartnerLink: statistics(eventName: $partnerLinkEventName, from: $startOfLastWeek, to: $endOfLastWeek, resolution: $lastWeekResolution) {
      ...statisticFields
    }
    lastWeekOnlineCalculator: statistics(eventName: $onlineCalculatorEventName, from: $startOfLastWeek, to: $endOfLastWeek, resolution: $lastWeekResolution) {
      ...statisticFields
    }
    currentMonthPartnerLink: statistics(eventName: $partnerLinkEventName, from: $startOfCurrentMonth, to: $endOfCurrentMonth, resolution: $currentMonthResolution) {
      ...statisticFields
    }
    currentMonthOnlineCalculator: statistics(eventName: $onlineCalculatorEventName, from: $startOfCurrentMonth, to: $endOfCurrentMonth, resolution: $currentMonthResolution) {
      ...statisticFields
    }
    lastMonthPartnerLink: statistics(eventName: $partnerLinkEventName, from: $startOfLastMonth, to: $endOfLastMonth, resolution: $lastMonthResolution) {
      ...statisticFields
    }
    lastMonthOnlineCalculator: statistics(eventName: $onlineCalculatorEventName, from: $startOfLastMonth, to: $endOfLastMonth, resolution: $lastMonthResolution) {
      ...statisticFields
    }
    currentYearPartnerLink: statistics(eventName: $partnerLinkEventName, from: $startOfCurrentYear, to: $endOfCurrentYear, resolution: $currentYearResolution) {
      ...statisticFields
    }
    currentYearOnlineCalculator: statistics(eventName: $onlineCalculatorEventName, from: $startOfCurrentYear, to: $endOfCurrentYear, resolution: $currentYearResolution) {
      ...statisticFields
    }
    lastYearPartnerLink: statistics(eventName: $partnerLinkEventName, from: $startOfLastYear, to: $endOfLastYear, resolution: $lastYearResolution) {
      ...statisticFields
    }
    lastYearOnlineCalculator: statistics(eventName: $onlineCalculatorEventName, from: $startOfLastYear, to: $endOfLastYear, resolution: $lastYearResolution) {
      ...statisticFields
    }
  }

  fragment statisticFields on Statistic {
    eventName
    from
    to
    resolution
    eventAggregations {
      resolution
      value
      from
      to
    }
  }
`;
