import React from 'react';
import { Mutation } from 'react-apollo';
import { Select } from '@blueprintjs/select';
import {
  Button,
  Classes,
  Intent,
  FormGroup,
  TextArea,
  Callout,
  MenuItem,
} from '@blueprintjs/core';
import {
  CHANGE_REQUEST_MUTATION,
  GET_CHANGE_REQUESTS,
} from './queries';
import LoadingIndicator from '../../common/LoadingIndicator';
import FilesUploader from '../../common/filesUploader';
import './CreateMutation.sass';

const reasonItems = [
  { value: '', title: 'Bitte wählen' },
  { value: 'sold', title: 'Boot verkauft' },
  { value: 'other', title: 'Sonstiges' },
];

class CreateMutation extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.handleClose = this.handleClose.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.onFilesChange = this.onFilesChange.bind(this);
    this.onDeleteFile = this.onDeleteFile.bind(this);
  }

  getInitialState() {
    return {
      error: '',
      reason: { value: reasonItems[0].value, errors: [], title: reasonItems[0].title },
      message: { value: '', errors: [] },
      attachments: { value: [], errors: [] },
    }
  }

  handleClose() {
    this.setState(this.getInitialState());
    this.props.closeCallback();
  }

  onMessageChange(e) {
    this.setState({ message: { ...this.state.message, value: e.target.value } });
  }

  onFilesChange(e) {
    this.setState({
      attachments: {
        ...this.state.attachments,
        value: this.state.attachments.value.concat(Object.values(e.target.files)),
      }
    });
  }

  onDeleteFile(index) {
    const attachments = this.state.attachments.value;
    attachments.splice(index, 1);
    this.setState({ attachments: { ...this.state.attachments, value: attachments } });
  }

  resetErrors() {
    this.setState({ reason: { ...this.state.reason, errors: [] } });
    this.setState({ message: { ...this.state.message, errors: [] } });
    this.setState({ attachments: { ...this.state.attachments, errors: [] } });
  }

  handleResponse(_cache, mutationResult) {
    this.resetErrors();
    const data = mutationResult.data.createChangeRequest;

    if (data.error && data.error.length > 0) {
      this.handleGlobalErrors(data.error);
    }
    else if (data.errors && data.errors.length > 0) {
      const newState = {...this.state};

      data.errors.forEach(error => {
        if (error.attribute === 'message') {
          newState.message.errors = error.messages;
        } else if (error.attribute === 'attachments') {
          newState.attachments.errors = error.messages;
        } else if (error.attribute === 'reason') {
          newState.reason.errors = error.messages;
        } else {
          this.handleGlobalErrors(`
            changeRequest attribute ${error.attribute} has errors ${error.messages.join(', ')}
          `);
        }
      });
      this.setState({...newState});
    }
    else {
      this.handleClose();
    }
  }

  handleGlobalErrors(errors) {
    global.Sentry.captureMessage(`
      Error by change request creation,
      contract: ${this.props.contractId},
      errors: ${errors}.
    `);
    this.setState({
      error: 'Beim erstellen des Änderungsvorschlag ist ein Fehler aufgetreten. Ein Administrator wurde bereits informiert. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.',
    });
  }

  render() {
    const errors = this.state.message.errors.join(', ');
    const intent = errors.length > 0 ? Intent.DANGER : Intent.NONE;
    const refetchQueries = [{
      query: GET_CHANGE_REQUESTS,
      variables: { id: this.props.contractId },
    }];

    const itemRenderer = item => {
      return (
        <MenuItem
          active={this.state.reason.value === item.value}
          key={item.value}
          onClick={() => this.setState({
            reason: {
              ...this.state.reason,
              value: item.value,
              title: item.title,
            },
          })}
          text={item.title}
        />
      );
    };

    return (
      <Mutation mutation={CHANGE_REQUEST_MUTATION} refetchQueries={refetchQueries}>
        {(createChangeRequest, { loading }) => (
          <React.Fragment>
            { loading && <LoadingIndicator /> }
            <div className={Classes.DIALOG_BODY}>
              <FormGroup intent={intent} label="Grund:">
                <Select
                  filterable={false}
                  items={reasonItems}
                  itemRenderer={itemRenderer}
                  onItemSelect={this.onReasonChange}
                >
                  <Button
                    rightIcon="double-caret-vertical"
                    text={this.state.reason.title}
                  />
                </Select>
                {this.state.reason.errors.length > 0 &&
                  <Callout className="error-message" intent={Intent.DANGER}>
                    <p>{this.state.reason.errors}</p>
                  </Callout>
                }
              </FormGroup>
              <FormGroup
                helperText={errors}
                intent={intent}
                label="Teilen Sie uns Ihren Änderungswunsch mit:"
              >
                <TextArea
                  rows="10"
                  autoFocus={true}
                  onChange={this.onMessageChange}
                  value={this.state.message.value || ''}
                  intent={intent}
                />
              </FormGroup>
              {this.state.error.length > 0 &&
                <Callout className="error-message" intent={Intent.DANGER}>
                  <p>{this.state.error}</p>
                </Callout>
              }
              <FilesUploader
                files={this.state.attachments.value}
                onChange={this.onFilesChange}
                onDelete={this.onDeleteFile}
                multiple={true}
                accept=".doc, .docx, .odt, .pdf, .jpg, .png"
                uploaderText="Fügen Sie alle Dokumente hinzu, die für die Bearbeitung des Änderungsantrags wichtig sind"
                buttonText="Dokumente hochladen"
              />
              {this.state.attachments.errors && this.state.attachments.errors.length > 0 &&
                <Callout className="error-message" intent={Intent.DANGER}>
                  <p>{this.state.attachments.errors}</p>
                </Callout>
              }
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={this.handleClose}>Abbrechen</Button>
                <Button
                  disabled={this.state.message.value.trim() === '' || this.state.reason.value === reasonItems[0].value}
                  intent={Intent.SUCCESS}
                  onClick={() => {
                    createChangeRequest({
                      variables: {
                        contractId: this.props.contractId,
                        reason: this.state.reason.value,
                        text: this.state.message.value,
                        attachments: {
                          uploads: this.state.attachments.value,
                        },
                      },
                      update: this.handleResponse,
                    });
                  }}
                >Absenden</Button>
              </div>
            </div>
          </React.Fragment>
        )}
      </Mutation>
    );
  }
}

export default CreateMutation;
